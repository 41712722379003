<template>
  <div class="mock-exam-column">
    <div class="column-wrapper">
      <div class="column-head">
        <div class="column-title">
          {{ columnTitle }}
        </div>
        <div class="column-intro">
          {{ columnIntro }}
        </div>
        <!-- <div class="column-more">
          <div class="button-more">
            More <i class="iconfont icon-arrow-right-bold" />
          </div>
        </div> -->
      </div>
      <div class="column-body">
        <div v-for="(item, index) in resources" :key="index" class="card-wrapper">
          <MockExamCard
            :key="index"
            v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: item.title })}]"
            :datetime="datetime"
            :datetime-format="datetimeFormat"
            :component-name="componentName"
            :component-type="componentType"
            :component-title="componentTitle"
            v-bind="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 模拟考试栏目web组件
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import MockExamCard from '../MockExamCard/web'

export default {
  name: 'MockExamColumn',
  components: {
    MockExamCard,
  },
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.mock-exam-column {
  position: relative;
  width: 1240px;
  margin: 0 auto;
  background: #fff0d7 url('./assets/icon-thinkacademy.png') right -18px no-repeat;
  background-size: 146px 198px;
  border-radius: 16px;

  .column-wrapper {
    padding: 40px;
  }

  .card-wrapper {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .column-head {
    margin-bottom: 40px;
  }

  .column-title {
    width: 850px;
    margin-bottom: 10px;
    color: var(--txt-color-link);
    font-size: 32px;
    font-weight: 600;
    word-break: break-all;
  }

  .column-intro {
    width: 850px;
    font-size: 16px;
    color: var(--txt-color-link);
    font-weight: 500;
    line-height: 20px;
    word-break: break-all;
  }

  .column-more {
    position: absolute;
    right: 40px;
    top: 50px;

    .button-more {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
      height: 36px;
      background: var(--txt-color-link3);
      color: var(--txt-color-link);
      font-size: 14px;
      font-weight: 500;
      border-radius: 24px;
      cursor: pointer;

      &:hover {
        background: var(--txt-color-link4);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      .icon-arrow-right-bold {
        font-size: 12px;
      }
    }
  }
}
</style>
