export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 栏目标题
    title: {
      default: '',
      type: String,
    },
    // 栏目介绍
    description: {
      default: '',
      type: String,
    },
    // 数据列表
    dataList: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    getVisuallyTrackData(item) {
      return {
        category_type: item.categoryType,
        goods_category_name: item.categoryName,
        goods_name: item.title,
        goods_id: item.id,
        goods_sell_price: item.sellPrice,
        previous_source: this.$route.name,
      }
    },
    trackReport(obj) {
      this.Sensors.track('universal_goods_card_click', obj)
    },
    cardClickHandler(cardData) {
      // 1 直播课 2 独立商品 3 录播课 4 自适应 5 代报名
      const goodTypeList = [2, 4, 5]
      if (cardData.categoryType === 3) {
        this.$router.push(`/courses/recorded-detail/${cardData.id}`)
      } else if (goodTypeList.includes(cardData.categoryType)) {
        this.$router.push(`/goods/detail/${cardData.id}`)
      }
      const reportData = this.getVisuallyTrackData(cardData)
      this.trackReport(reportData)
    },
  },
}
