export default {
  props: {
    // 打开方式
    openType: {
      default: 1, // 0: 当前页 1: 新窗口
      type: Number,
    },
  },
  computed: {
    linkTarget() {
      return this.openType === 1 ? '_blank' : '_self'
    },
  },
  methods: {
    getLinkTarget(openType) {
      return openType === 1 ? '_blank' : '_self'
    },
  },
}
