<template>
  <div class="wrapper course-detail-column">
    <ModuleTextHeader :title="title" :describe="description" />
    <div class="course-detail-column-content" :class="{ 'center-three': dataList.length < 3 }">
      <div
        v-for="(item, index) in dataList"
        :key="index"
        v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: item.showName })}]"
        class="courses-card-item-wrapper"
        @click="cardTrackReport({ cardName: item.showName })"
      >
        <CoursesCard :course-detail="item" />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleTextHeader from '../Common/ModuleTextHeader/web'
import CoursesCard from './CoursesCard/web'

export default {
  components: { ModuleTextHeader, CoursesCard },
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.course-detail-column {
  margin-top: 40px;
}

.common-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--txt-color-lv1);
  line-height: 22px;
}

.course-detail-column-content {
  display: flex;
  flex-wrap: wrap;

  .courses-card-item-wrapper {
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #dee2e7;

    &:nth-child(3n + 2) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.center-three {
  justify-content: center;
}
</style>
