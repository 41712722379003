<template>
  <div class="wrapper faq-card">
    <h4 class="faq-title">
      {{ title }}
    </h4>
    <ul class="fap-list">
      <li
        v-for="(faq, index) in dataList"
        :key="`faq-${index}`"
        v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: faq.title })}]"
        class="faq-item"
        @click="changeAnswerStatus(index); cardTrackReport({ cardName: faq.title })"
      >
        <div class="question-container" :class="{'high-light': answerHeight[index] }">
          <span class="que-seq">{{ `${index + 1}.` }}</span>
          <span class="que-title">{{ faq.title }}</span>
          <div class="que-icon" />
        </div>
        <div class="answer-container" :style="{'height': answerHeight[index] || 0}">
          <div :ref="'fagAnswer' + index" class="answer" v-html="faq.description.replace(/[\r\n]/g, '<br />')" />
          <img v-if="faq.webImage" :ref="'fagPic' + index" class="pic" :data-not-lazy="true" v-lazy-load :data-src="faq.webImage" alt="webImage" :onload="imgLoaded(index)" v-lazy-load>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'

export default {
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.faq-card {
  padding: 50px;
  background: rgba(245, 245, 245, 1);
}

.faq-title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
}

ul.fap-list {
  list-style: none;
  padding: 0;
}

li.faq-item {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 16px;
  background: #fff;
  border: 2px solid rgba(34, 34, 34, 0.05);
  cursor: pointer;

  .question-container {
    position: relative;
    display: flex;
    padding-right: 40px;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: var(--txt-color-lv2);
    transition: all 0.05s;

    .que-seq {
      width: 30px;
      min-width: 30px;
    }

    &:hover {
      color: var(--txt-color-lv1);
    }
  }

  .que-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.4s;
    background: url('./assets/imgs/icon_more.png') 0/100% no-repeat;
  }

  .high-light {
    color: var(--txt-color-lv1);

    .que-icon {
      transform: rotate(-180deg);
    }
  }

  .answer-container {
    height: 0;
    overflow: hidden;
    transition: height 0.4s;

    .answer {
      padding: 20px 30px 0;
      font-size: 16px;
      line-height: 24px;
      color: var(--txt-color-lv1);
    }

    .pic {
      width: calc(100% - 60px);
      border-radius: 8px;
      margin: 20px 30px 0;
      border: 1px solid whitesmoke;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
