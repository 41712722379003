<template>
  <div class="header">
    <div class="title text-overflow-ellipsis-2">
      {{ title }}
    </div>
    <div v-if="describe" class="describe text-overflow-ellipsis-4">
      {{ describe }}
    </div>
  </div>
</template>

<script>
/**
 *  模块头部
 */
import mixin from './mixin';

export default {
  mixins: [mixin],
}
</script>
<style lang="scss" scoped>
.header {
  text-align: center;
  color: var(--txt-color-lv1);
  margin-bottom: 20px;

  .title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
  }

  .describe {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
