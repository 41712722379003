export default {
  props: {
    avatar: {
      default: '',
      type: String,
    },
    name: {
      default: '',
      type: String,
    },
    education: {
      default: '',
      type: String,
    },
    identityTag: {
      default: '',
      type: String,
    },
    experience: {
      default: '',
      type: String,
    },
  },
}
