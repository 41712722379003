<template>
  <div class="wrapper customer-form-wrapper">
    <div v-if="title || intro" class="form-head">
      <div v-if="title" class="title" v-text="title" />
      <div v-if="intro" class="description" v-text="intro" />
    </div>
    <div class="form-body">
      <el-form ref="formData" :model="formData" :hide-required-asterisk="true">
        <div class="form-wrapper">
          <template v-for="(item, index) in formData.dataList">
            <div :key="index" class="form-item">
              <el-form-item :prop="'dataList.' + index + '.value'" :rules="item.rules">
                <template v-if="item.type === 'input'">
                  <el-input v-model="item.value" :placeholder="item.name" />
                </template>
                <template v-else-if="item.type === 'select'">
                  <el-select v-model="item.value" :placeholder="item.name" :multiple="item.multiple" popper-class="normal-grace-dropdown">
                    <el-option
                      v-for="(option, optionIndex) in item.options"
                      :key="optionIndex"
                      :label="option.name"
                      :value="optionIndex"
                    />
                  </el-select>
                </template>
              </el-form-item>
            </div>
          </template>
        </div>

        <div class="button-wrapper">
          <el-button
            class="button common-button-normal"
            :loading="loading"
            @click="handleSubmit"
          >
            {{ submitName }}
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
/**
 * 自定义表单
 */
import mixin from './mixin'

export default {
  name: 'CustomerForm',
  mixins: [mixin],
  methods: {
    // 处理提交按钮操作
    handleSubmit() {
      this.$refs.formData.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.save({
          successCallback: () => {
            this.$ElMessage({
              type: 'success',
              message: this.successNotice,
            })
            this.$refs.formData.resetFields()
          },
          failCallback: (msg) => {
            this.$ElMessage({
              message: msg,
              type: 'warning',
            })
          },
        }, {
          sourceCode: 'website',
          mediaCode: 'pc_lead',
        })
        return true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-form-wrapper {
  padding: 40px 0;
}

.form-head {
  width: 1000px;
  margin: 0 auto;

  .title {
    line-height: 30px;
    color: var(--txt-color-lv1);
    font-size: 24px;
    text-align: center;
  }

  .description {
    margin-top: 20px;
    font-size: 14px;
    color: var(--txt-color-lv1);
    line-height: 20px;
    text-align: center;
  }
}

.form-body {
  width: 960px;
  margin: 0 auto;
  margin-top: 40px;

  .form-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  /deep/.el-form-item {
    border: none;
    background: none;

    .el-input__suffix {
      right: 25px;
    }

    .el-form-item__error {
      left: 30px;
    }

    .el-select {
      display: inline-block;
      width: 100%;

      .el-select__tags {
        margin: 0 25px;
        height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .el-icon-arrow-up {
        color: #171717;
        font-weight: bold;
      }
    }

    .el-form-item__content {
      border: 1px solid rgba(23, 43, 77, 0.1);
      border-radius: 4px;
      line-height: unset;
    }

    .el-input__inner {
      height: 60px;
      border: none;
      background: none;
      font-weight: 500;
      font-size: 16px;
      color: var(--txt-color-lv1);
      padding: 0 30px;
      font-family: 'Montserrat', 'SF Pro Rounded', 'SF Pro', 'Helvetica', 'Roboto', 'Arial', sans-serif;

      &::placeholder {
        color: var(--txt-color-lv2);
      }
    }

    .el-input-group__prepend {
      border: none;
      background: none;
    }
  }

  .form-item {
    width: 470px;

    &:nth-child(odd) {
      margin-right: 20px;
    }

    &:nth-child(odd):last-child {
      width: 960px;
      margin-right: 0;
    }
  }

  .button-wrapper {
    text-align: center;

    .common-button-normal {
      height: 48px;
      display: inline-flex;
      line-height: 48px;
      width: 160px;
    }
  }
}
</style>
