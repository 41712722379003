<template>
  <div class="container">
    <div class="head">
      <div class="advator">
        <img :data-src="imageUrl" alt="" loading="lazy" class="swiper-lazy">
      </div>
      <div class="title">
        <div class="title1 text-overflow-ellipsis">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="subtitle text-overflow-ellipsis-3">
      {{ subTitle }}
    </div>
    <div class="desc">
      {{ describe }}
    </div>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  mixins: [mixin],
}
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 17px 23px 9px rgba(162, 170, 184, 0.06);
  border-radius: 10px;
  padding: 31px 20px 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);

  .head {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: default;

    .advator {
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.05);
      }
    }

    .title {
      max-width: 240px;
      margin-left: 10px;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;

      .title1 {
        max-width: 240px;
      }
    }
  }

  .subtitle {
    font-size: 14px;
    font-weight: 500;
    color: #222;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .desc {
    max-height: 244px;
    word-break: break-word;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    color: var(--txt-color-lv1);
    line-height: 20px;
    cursor: default;
  }
}
</style>
