import moment from 'moment-timezone'
import { oneApi } from 'config/api'

export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 卡片标题
    title: {
      default: '',
      type: String,
    },
    // 难度
    difficulty: {
      default: 0,
      type: Number,
    },
    // 简介
    intro: {
      default: '',
      type: String,
    },
    // 模拟考试时长
    examMockDuration: {
      default: 0,
      type: Number,
    },
    // 参与人数
    examPersonNums: {
      default: 0,
      type: Number,
    },
    // 下载资源格式类型
    downloadFormatType: {
      default: 0,
      type: Number,
    },
    // 时间
    datetime: {
      default: 0,
      type: Number,
    },
    // 时间格式
    datetimeFormat: {
      default: 'DD-MM-YY',
      type: String,
    },
    // 按钮组
    buttons: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      langContext: this.$t('dynamicComponents.MockExamCard'),
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin;
    },
  },
  methods: {
    moment,
    /**
     * 诊断考试页跳转
     * @param {Number} id 诊断id
     */
    async examJump(id) {
      const params = {
        examId: id,
      }
      const res = await this.$axios.post(oneApi.queryLatestTest, params, { rewritePostBody: true })
      const { data } = res || {}
      // 诊断页跳转状态 0: 考试页 1: 结果页
      let examStatus = 0
      // status 0:流程中, 1:超时提交, 2:手动提交
      // maxAnswerTime: 最大作答次数
      // curAnswerTime: 当前作答次数
      // 当考试已提交并且当前作答次数大于等于最大作答次数时
      if (data && (data.status === 1 || data.status === 2) && data.curAnswerTime >= data.maxAnswerTime) {
        examStatus = 1
      }
      // 未考试
      if (examStatus === 0) {
        window.location.href = window.JsInjectionActivity ? `${window.location.origin}/quiz/evaluation/instruction/${id}?reportType=2` : `${window.location.origin}/quiz/evaluation/instruction/${id}`
        return
      }
      // 已考试
      if (examStatus === 1) {
        window.location.href = window.JsInjectionActivity ? `${window.location.origin}/quiz/evaluation/result/${data.sessionId}?examId=${id}&reportType=2` : `${window.location.origin}/quiz/evaluation/result/${data.sessionId}?examId=${id}`
      }
    },
  },
}
