<template>
  <div class="wrapper">
    <ModuleTextHeader :title="title" :describe="describe" />
    <div class="ad1-content">
      <DiffLinkLabel
        v-for="(item, index) in dataList" :key="index"
        :url="item.linkUrl" :target="linkTarget"
      >
        <div class="ad1-wraper" @click="cardTrackReport({ cardName: item.title })">
          <img v-lazy-load :data-src="item.imageUrl" alt="" v-lazy-load>
          <div class="text-overflow-ellipsis item-title">
            {{ item.title }}
          </div>
        </div>
      </DiffLinkLabel>
    </div>
    <div v-if="buttonName" class="ad2-bottom">
      <DiffLinkLabel :url="buttonUrl" :target="linkTarget">
        <div class="button btn-wraper" @click="buttonTrackReport({ buttonName })">
          <div class="ad2-btn text-overflow-ellipsis">
            {{ buttonName }}
          </div>
        </div>
      </DiffLinkLabel>
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（一）
 */
import mixin from './mixin';
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleTextHeader from '../Common/ModuleTextHeader/web.vue'
import DiffLinkLabel from '../Common/DiffLinkLabel/web.vue'

export default {
  name: 'Ad1',
  components: {
    ModuleTextHeader,
    DiffLinkLabel,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
}
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 20px 180px;
}

.ad1-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .ad1-wraper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;

    img {
      width: 74px;
      height: 60px;
      margin-bottom: 10px;
    }

    .item-title {
      max-width: 180px;
      text-align: center;
    }
  }
}

.ad2-bottom {
  margin: 40px auto 0;
  max-width: 216px;
  display: flex;
  justify-content: center;

  .btn-wraper {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    border-radius: 70px;
    background: var(--txt-color-link);

    .ad2-btn {
      max-width: 222px;
      min-width: 137px;
      padding: 12px 15px;
    }

    &:hover {
      background: var(--txt-color-link2);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
</style>
