<template>
  <div class="wrapper course-detail-column">
    <ModuleTextHeader :title="title" :describe="description" />
    <div class="course-detail-column-content" :class="{ 'center-three': dataList.length < 3 }">
      <div
        v-for="(item, index) in dataList"
        :key="index"
        v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: item.title })}]"
        class="card-wraper"
        @click="cardTrackReport({ cardName: item.title })"
      >
        <DiffLinkLabel :url="item.linkUrl">
          <div class="card-content">
            <div class="title text-overflow-ellipsis-2">
              {{ item.title }}
            </div>
            <div class="common-text text-overflow-ellipsis-3">
              {{ item.description }}
            </div>
          </div>
          <div class="card-bottom">
            <div class="price text-overflow-ellipsis">
              {{ item.price }}
            </div>
          </div>
        </DiffLinkLabel>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleTextHeader from '../Common/ModuleTextHeader/web'
import DiffLinkLabel from '../Common/DiffLinkLabel/web'

export default {
  components: { ModuleTextHeader, DiffLinkLabel },
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.course-detail-column-content {
  display: flex;
  flex-wrap: wrap;

  .card-wraper {
    margin-right: 20px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #dee2e7;
    cursor: pointer;

    .card-content {
      width: 398px;
      height: 166px;
      padding: 20px;
      background: url('./assets/imgs/bg_web.png') 0/100% no-repeat;

      .title {
        margin-bottom: 14px;
        font-size: 18px;
        font-weight: bold;
        color: var(--txt-color-lv1);
        line-height: 26px;
      }

      .common-text {
        font-size: 14px;
        font-weight: 400;
        color: var(--txt-color-lv1);
        line-height: 22px;
      }
    }

    .card-bottom {
      padding: 20px;
      text-align: right;

      .price {
        display: inline-block;
        width: 358px;
        font-size: 20px;
        font-weight: bold;
        color: var(--txt-color-link);
        line-height: 28px;
      }
    }

    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }
}

.center-three {
  justify-content: center;
}
</style>
