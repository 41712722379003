export default {
  props: {
    // 组件名称
    componentName: {
      default: 'MockExamColumn',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 栏目标题
    columnTitle: {
      default: '',
      type: String,
    },
    // 栏目简介
    columnIntro: {
      default: '',
      type: String,
    },
    // 列缩写
    columnAbbr: {
      default: '',
      type: String,
    },
    // 时间
    datetime: {
      default: 0,
      type: Number,
    },
    // 时间格式
    datetimeFormat: {
      default: 'DD-MM-YY',
      type: String,
    },
    // 资源列表
    resources: {
      default: () => [],
      type: Array,
    },
  },
}
