<template>
  <div class="UserCommentsBigPicture-box">
    <div v-if="columntheme" class="theme">
      {{ columntheme }}
    </div>
    <div v-if="describe" class="themeIntro">
      {{ describe }}
    </div>
    <div class="swiper-box" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave">
      <div ref="swiper" v-swiper:mySwiper="swiperOption" class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(item, index) in dataList" :key="index" class="swiper-slide">
            <div class="left">
              <span class="left_title">{{ item.title }}</span>
              <span class="left_subheading">{{ item.subtitle }}</span>
              <span class="left_text">{{ item.describe }}</span>
            </div>
            <div class="right">
              <img class="right_img swiper-lazy" :data-src="item.imageUrl" alt="" >
            </div>
          </div>
        </div>
        <div class="swiper-button-prev" v-if="dataList.length > 1">
          <div class="iconfont icon-prev2" />
        </div>
        <div class="swiper-button-next" v-if="dataList.length > 1">
          <div class="iconfont icon-next2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from './mixin';
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

  export default {
    components: { Swiper, SwiperSlide },
    directives: {
      swiper: directive,
    },
    mixins: [mixin],
    data(props) {
      return {
        swiperOption: {
          // slidesPerView: 'auto',
          // loop: false,
          // preloadImages: false,
          // loopAdditionalSlides: 1,
          // loopedSlides: 3,
          // autoplay: {
          //   delay: 3000,
          //   disableOnInteraction: false,
          // },
          // speed: 500,
          // autoplayDisableOnInteraction: true,
          // observer: true,
          // observeParents: true,
          // observeSlideChildren: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          lazy:{
            loadPrevNext:true, // 预加载前一个后一个
          },
          observer:true, // 当Swiper的监听器检测到swiper的一些属性变化时，会更新Swiper的状态
          observeParents:true, // 当Swiper的父元素变化时，也会更新Swiper的状态
        },
      };
    },
    mounted() { },
    methods: {
      handleMouseenter() {
        this.mySwiper.autoplay.stop();
        console.log('触发');
      },
      handleMouseleave() {
        this.mySwiper.autoplay.start();
        console.log('触发');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .UserCommentsBigPicture-box {
    width: 1240px;
    margin: 0 auto;

    .theme {
      width: 1080px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 24px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #222222;
      line-height: 32px;
      text-align: center;
      margin: 0 auto 20px;
    }


    .themeIntro {
      font-size: 14px;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      color: #222;
      line-height: 22px;
      text-align: center;
      margin-bottom: 20px;
      text-align: center;
    }

    .swiper-container {
      position: unset;
      --swiper-navigation-color: #FFA827;
      --swiper-navigation-size: 30px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 60px;
      height: 60px;
      background: rgba(0, 0, 0, 0.2);
      top: 50%;
      border-radius: 50%;
      line-height: 60px;
      text-align: center;
      color: #ffffff;
      display: none;
    }

    /* 鼠标移入Swiper容器时显示按钮 */
    .swiper-container:hover .swiper-button-next,
    .swiper-container:hover .swiper-button-prev {
      display: block;
    }

    .swiper-button-next:hover,
    .swiper-button-prev:hover {
      background: rgba(0, 0, 0, 0.4);
    }

    .swiper-button-prev {
      left: -30px;
      background-image: none;
      /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23FFA827'%2F%3E%3C%2Fsvg%3E"); */
      /* 颜色值修改 FFA827 */
    }

    .swiper-button-next {
      right: -30px;
      background-image: none;
      /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23FFA827'%2F%3E%3C%2Fsvg%3E"); */
      /* 颜色值修改 FFA827 */
    }

    .swiper-box {
      position: relative;
    }

    .swiper-slide,
    .slide {
      display: flex;
      justify-content: space-between;

      .left {
        width: 820px;
        height: 380px;
        padding: 40px;

        .left_title {
          display: block;
          font-weight: bold;
          color: #222222;
          font-size: 28px;
          line-height: 32px;
          font-family: Montserrat-Bold, Montserrat;
        }

        .left_subheading {
          display: inline-block;
          padding: 7px 16px 7px 16px;
          background: #FFF3E0;
          border-radius: 4px;
          font-size: 14px;
          line-height: 16px;
          margin-top: 17px;
          margin-left: 16px;
          font-family: Montserrat-Medium, Montserrat;
          font-weight: 500;
          color: #FFA827;
        }

        .left_text {
          display: inline-block;
          margin-top: 30px;
          font-size: 16px;
          line-height: 26px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 8;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: Montserrat-Medium, Montserrat;
          font-weight: 500;
          color: #4E4E4E;
        }
      }

      .right {
        width: 380px;
        height: 380px;

        .right_img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 15px;
        }
      }
    }
  }
</style>