<template>
  <div class="class-column-wrapper wrapper">
    <ModuleTextHeader :title="title" :describe="description" />
    <div class="class-card-wrappoer" :class="{ 'center-three': dataList.length < 3 }">
      <div v-for="(classItem, index) in dataList" :key="index" class="class-card-item-wrapper" @click="cardTrackReport({ cardName: classItem.title })">
        <ClassCard
          v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: classItem.title })}]"
          class="class-card-in-list"
          :card-data="classItem"
          :show-wrapper-border="true"
          :show-original-price="true"
          :show-taxation-desc="true"
          :group-buy="classItem.activityItem"
          :show-price-is-include="classItem.showPriceIsInclude === 1"
          :show-card-bottom-left="true"
          :total-lessons="classItem.spec.lessonCount"
          :left-lessons="classItem.spec.leftLesson"
          :show-right-top-tag="!classItem.spec.courseClosed"
          :show-back-stamp="true"
          :tag-type="getClassHotTagType(classItem.store, classItem.sellStore, classItem.remainSellTime)"
          :show-per-lesson="classItem.perShowPrice !== undefined"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 通用图片组件
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleTextHeader from '../Common/ModuleTextHeader/web'
import ClassCard from 'components/courses/ClassCard'

export default {
  name: 'ClassColumn',
  components: {
    ModuleTextHeader,
    ClassCard,
  },
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.class-card-wrappoer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  &.center-three {
    justify-content: center;
  }

  .class-card-item-wrapper {
    margin-bottom: 20px;

    &:nth-child(3n + 2) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .class-card-in-list {
    width: 400px;
  }
}
</style>
