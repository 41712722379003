import evaluation from 'common/evaluation'

export default {
  props: {
    // 组件名称
    componentName: {
      default: 'NumberScroll',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 标题
    title: {
      default: '',
      type: String,
    },
    // 栏目简介
    description: {
      default: '',
      type: String,
    },
    // 诊断ID
    examId: {
      default: 0,
      type: [Number, String],
    },
  },
  data() {
    return {}
  },
  methods: {
    handleToEvaluation() {
      evaluation.toEvaluation(this.$store, `/evaluation/instruction/${this.examId}`, 'class_register_pop_up')
      this.buttonTrackReport()
    },
  },
}
