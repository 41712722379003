import classCard from '~/mixins/courses/classCard.js';

export default {
  mixins: [classCard],
  props: {
    // 订单页面
    isOrder: {
      type: Boolean,
      default: false,
    },
    cardData: {
      type: Object,
      default: () => ({}),
    },
    needTags: {
      type: Array,
      default: () => [],
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    hasBottom: {
      type: Boolean,
      default: false,
    },
    // 是否需要标题中的标签
    showDateContent: {
      type: Boolean,
      default: false,
    },
    // 展示价格price还是日期date
    bottomContent: {
      type: String,
      default: '',
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: Number,
      default: 4,
    },
    closeIcon: {
      default: false,
      type: Boolean,
    },
    studentUid: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      isKeepTwoDecimal: this.$t('common.isKeepTwoDecimal'),
      recordedConfig: this.$t('featureComponents.Goods.recordingDetail'),
      locale: this.$store.state.locale,
      weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      dateFormat: '',
      clientOptions: '',
    };
  },
  async mounted() {
    const clientOptions = JSON.parse(window.localStorage.getItem('clientOptions'))
    if (!clientOptions) {
      await this.$store.dispatch('ucenter/getClientOptions', { $axios: this.$axios });
    }
    this.clientOptions = JSON.parse(window.localStorage.getItem('clientOptions'))
    const dateFormatMap = {
      UK: 'DD/MM/YYYY',
      FR: 'DD/MM/YYYY',
      JP: 'YYYY/MM/DD',
    }
    this.dateFormat = dateFormatMap[this.clientOptions.dateFormat] || 'MM/DD/YYYY'
  },
  computed: {
    currency() {
      return this.$store.state.currency.symbol;
    },
    minorUnit() {
      return this.$store.state.currency.minorUnit;
    },
    timezone() {
      return this.$store.state.timezone
    },
    allTeacherList() {
      if (!this.cardData.spec) return [];
      const tutorList = this.cardData.spec.tutorList || [].concat(this.cardData.spec.tutorList);
      const teacherList = this.cardData.spec.teacherList || [].concat(this.cardData.spec.teacherList);
      return this.cardData.spec.tutorList ? teacherList.concat(tutorList) : teacherList;
    },
    // 订单的信息
    teacherName() {
      return this.cardData?.spec?.teacherList[0]?.sysName
    },
  },
  methods: {
    getTeacherType(type) {
      if (Number(type) === 1) {
        return this.$store.state.teacher.lecturerTitle;
      }
      return this.$store.state.teacher.assistantTitle;
    },
    // 点击跳转详情页
    cardClickHandler() {
      if (!this.clickable) { return }
      const classId = this.cardData.classId || this.cardData.studentCourseId
      this.$router.push({
        path: '/my-courses/recorded-courses/detail',
        query: { studentCourseId: classId, studentId: this.studentUid || '' },
      })
    },
  },
}
