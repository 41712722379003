<template>
  <div class="link-container">
    <a v-if="url" :href="url" :target="target">
      <slot />
    </a>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  mixins: [mixin],
}
</script>
