export default {
  props: {
    // 组件名称
    componentName: {
      default: 'ModuleRichText',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 标题
    title: {
      default: '',
      type: String,
    },
    // 内容
    content: {
      default: '',
      type: String,
    },
  },
}
