export default {
  props: {
    url: {
      default: '',
      type: String,
    },
    target: {
      default: '',
      type: String,
    },
  },
}
