export default {
  props: {
    // 课程详情数据
    courseDetail: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    linkUrl() {
      return `/courses/available-classes/${this.courseDetail.courseId}`
    },
  },
}
