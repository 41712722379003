<template>
  <div class="text-above-img-wrapper">
    <img :data-src="imageUrl" alt="" loading="lazy" class="swiper-lazy">
    <div class="txt-common">
      <div class="txt-wraper">
        <div class="desc">
          {{ describe }}
        </div>
        <div class="title">
          {{ title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  mixins: [mixin],
};
</script>
<style lang="scss" scoped>
.text-above-img-wrapper {
  width: 460px;
  height: 300px;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  img {
    width: 460px;
    height: 300px;
  }

  .txt-common {
    position: absolute;
    width: 100%;
    top: 242px;
    transition: transform 0.3s linear;

    .txt-wraper {
      padding: 20px;
      line-height: 18px;
      font-weight: 500;
      color: white;
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: -58px;
      background-color: rgba(0, 0, 0, 0.6);

      .desc {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        -webkit-line-clamp: 12;
        -webkit-box-orient: vertical;
        visibility: hidden;
      }

      .title {
        display: block;
        max-width: 420px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &:hover {
    .txt-common {
      transform: translateY(-100%);

      .txt-wraper {
        flex-direction: column;

        .title {
          visibility: hidden;
        }

        .desc {
          visibility: visible;
        }
      }
    }
  }
}
</style>
