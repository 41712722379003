export default {
  props: {
    // 组件名称
    componentName: {
      default: 'Banner',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 轮播图列表
    bannerList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      activedIndex: 0,
    }
  },
  computed: {
    bannerListLength() {
      if (!Array.isArray(this.bannerList)) return 0
      return this.bannerList.length;
    },
  },
  methods: {
    imageChangeHandler(newIndex) {
      this.activedIndex = newIndex
    },
    getResourceType(item) {
      if (item.widgets && Array.isArray(item.widgets) && item.widgets.length > 0) {
        if (item.widgets.every((widget) => widget.type === 1)) {
          return 2
        }
      }
      return 1
    },
  },
}
