export default {
  props: {
    // 课程详情数据
    courseDetail: {
      default: () => {},
      type: Object,
    },
    // 是否展示适合范围信息
    showSuitable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isKeepTwoDecimal: this.$t('common.isKeepTwoDecimal'),
      langContext: this.$t('dynamicComponents.Course'),
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    sellPrice() {
      const { perShowPrice, showPrice } = this.courseDetail
      if (perShowPrice !== undefined) return Number(perShowPrice)
      return Number(showPrice)
    },
    linkUrl() {
      return `/courses/available-classes/${this.courseDetail.courseId}`
    },
    minorUnit() {
      return this.$store.state.currency.minorUnit;
    },
    currency() {
      return this.$store.state.currency.symbol
    },
  },
}
