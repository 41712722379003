<template>
  <div class="courses-column-wrapper wrapper">
    <ModuleTextHeader :title="title" :describe="description" />
    <div class="courses-card-wrappoer" :class="{ 'center-three': dataList.length < 3 }">
      <div
        v-for="(item, index) in dataList"
        :key="index"
        v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: item.showName })}]"
        class="courses-card-item-wrapper"
        @click="cardTrackReport({ cardName: item.showName })"
      >
        <CoursesCard :course-detail="item" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 课程栏目(二)
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleTextHeader from '../Common/ModuleTextHeader/web'
import CoursesCard from './CoursesCard/web'

export default {
  name: 'CoursesColumn2',
  components: {
    ModuleTextHeader,
    CoursesCard,
  },
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.courses-card-wrappoer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .courses-card-item-wrapper {
    margin-bottom: 20px;
    border: 1px solid #dee2e7;
    border-radius: 10px;

    &:nth-child(3n + 2) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.center-three {
  justify-content: center;
}
</style>
