<template>
  <div>
    <img data-not-lazy class="teacher-avator" v-lazy-load :data-src="`${avatar}?im=Resize=(480,480)`" alt="" loading="lazy" width="240px" height="240px">
    <div class="teacher-info">
      <div class="name text-overflow-ellipsis-2">
        {{ name }}
      </div>
      <div v-if="education" class="identityTag text-overflow-ellipsis-2">
        {{ `# ${education}` }}
      </div>
      <div v-if="identityTag" class="identityTag text-overflow-ellipsis-2">
        {{ `# ${identityTag}` }}
      </div>
      <div v-if="experience" class="experience text-overflow-ellipsis-4">
        {{ experience }}
      </div>
    </div>
  </div>
</template>
<script>
import mixin from './mixin';

export default {
  mixins: [mixin],
}
</script>
<style lang="scss" scoped>
.teacher-avator {
  width: 240px;
  height: 240px;
}

.teacher-info {
  flex-shrink: 0;
  margin-left: 20px;
  max-width: 240px;

  .name {
    max-width: 240px;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
  }

  .identityTag {
    max-width: 240px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: var(--txt-color-link);
    line-height: 21px;

    &:hover {
      color: var(--txt-color-link2);
    }
  }

  .experience {
    margin-top: 40px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
