<template>
  <div class="wrapper">
    <ModuleTextHeader :title="title" :describe="describe" />
    <div
      ref="mySwiper" v-swiper:mySwiper="swiperOption" class="swiper-container"
      @mouseenter="handleMouseenter" @mouseleave="handleMouseleave"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in dataList"
          :key="index"
          class="swiper-slide"
          @click="cardTrackReport({ cardName: item.title })"
        >
          <TextAboveImg
            :image-url="item.imageUrl"
            :title="item.title"
            :describe="item.describe"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（四）
 */
import mixin from './mixin'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleTextHeader from '../Common/ModuleTextHeader/web.vue'
import TextAboveImg from './TextAboveImg/web.vue'

export default {
  name: 'Ad4',
  /* eslint-disable-next-line */
  components: { ModuleTextHeader, TextAboveImg, Swiper, SwiperSlide,},
  directives: {
    swiper: directive,
  },
  mixins: [mixin, trackReportMixin],
  data() {
    const autoplay = this.dataList.length > 1 ? { delay: 2000, disableOnInteraction: false } : false
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        lazy:{
          loadPrevNext:true, // 预加载前一个后一个
        },
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        preloadImages: false,
        loopAdditionalSlides: 2,
        loopedSlides: 5,
        autoplay,
        speed: 1500,
        autoplayDisableOnInteraction: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
      },
    }
  },
  mounted() {
    // this.$refs.mySwiper.swiper.autoplay.stop()
  },
  methods: {
    handleMouseenter() {
      this.$refs.mySwiper.swiper.autoplay.stop()
    },
    handleMouseleave() {
      this.$refs.mySwiper.swiper.autoplay.start()
    },
  },
}
</script>
<style lang="scss" scoped>
.swiper-container {
  .swiper-slide {
    width: 460px;
    height: 300px;
    cursor: pointer;
  }
}
</style>
