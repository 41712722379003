export default {
  name: 'Map',
  props: {
    // 组件名称
    componentName: {
      default: 'Map',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    mapUrl: {
      default: '',
      type: String,
    },
    imgUrl: {
      default: '',
      type: String,
    },
    leftContent: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    description: {
      default: '',
      type: String,
    },
  },
}
