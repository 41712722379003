import { classHotTagType } from 'common/map'

export default {
  props: {
    // 组件名称
    componentName: {
      default: 'ClassColumn',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 栏目标题
    title: {
      default: '',
      type: String,
    },
    // 栏目描述
    description: {
      default: '',
      type: String,
    },
    // 班级数据列表
    dataList: {
      default: () => [],
      type: Array,
    },
  },

  methods: {
    // 获取班级热度标签类型
    getClassHotTagType(store, sellStore, remainSellTime) {
      return classHotTagType(store, sellStore, remainSellTime)
    },
  },
}
