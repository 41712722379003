<template>
  <div :class="[resourceType==='video' ? 'resource-video-wraper' : 'resource-wraper-container']">
    <div v-for="(item, index) in dataList" :key="'resource' + index" :class="[resourceType==='video' ? 'video-content' : 'resource-content']">
      <template v-if="resourceType==='video'">
        <VideoType :data-item="item" v-bind="$props" v-on="$listeners" />
      </template>
      <template v-else>
        <div class="resource-title text-overflow-ellipsis-2">
          {{ item.resourceTitle }}
        </div>
        <div class="resource-wraper" :class="classType">
          <ResourceItem
            v-for="(item1, index1) in item.resourceList" :key="'resourceItem' + index1"
            :resource-type="item1.resourceFormatType" :resource-id="`${item1.resourceId}`"
            :resource-name="item1.buttonName"
            @download="handleDownload" @play="handlePlay"
          />
          <div class="split-line" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import ResourceItem from '../../Common/ResourceItem/web'
import VideoType from './VideoType.vue'

export default {
  components: {
    ResourceItem,
    VideoType,
  },
  mixins: [mixin],
  data() {
    return {
      showNum: 8,
    }
  },
}
</script>

<style lang="scss" scoped>
.resource-video-wraper {
  display: flex;
  gap: 38px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.resource-content {
  padding: 20px;
  margin-top: 40px;
  border-radius: 12px;
  background: #fff;
  border: 2px solid #ebebeb;

  .resource-title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: var(--txt-color-lv1);
  }

  .resource-wraper {
    display: flex;
  }

  /deep/.resouce-item-container {
    flex: 1;
  }

  .double-wraper {
    position: relative;

    .split-line {
      width: 2px;
      height: 44px;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, -23%);
      background: #ebebeb;
    }

    /deep/.resouce-item-container {
      &:nth-child(2n+1) {
        margin-right: 49px;
      }

      &:nth-child(2n+2) {
        margin-left: 49px;
      }
    }
  }
}
</style>
