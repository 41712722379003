export default {
  props: {
    // 组件名称
    componentName: {
      default: 'GetNotified',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 分校识别码
    locale: {
      default: '',
      type: String,
    },
    // 标题
    title: {
      default: '',
      type: String,
    },
    // 简介
    intro: {
      default: '',
      type: String,
    },

    /**
     * 表单字段名称配置
     */
    firstName: {
      default: 'First Name',
      type: String,
    },
    lastName: {
      default: 'Last name',
      type: String,
    },
    gradeName: {
      default: 'Your child\'s grade',
      type: String,
    },
    emailName: {
      default: 'Email',
      type: String,
    },
  },
  data() {
    const config = this.getConfig()
    return {
      submitName: config.submitName,
      agreementNotice: config.agreementNotice,
      successNotice: config.successNotice,
      rules: config.rules,
      loading: false,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        gradeId: '',
        gradeName: '',
        subStatus: true,
      },
    }
  },
  computed: {
    gradeList() {
      const gradeList = []
      this.$store.state.courses.gradeList.forEach((item) => {
        item.list.forEach((grade) => {
          gradeList.push(grade)
        })
      })
      return gradeList
    },
  },
  methods: {
    async init() {
      const gradeList = this.$store.state.courses.gradeList || []
      // 初始化年级数据
      if (Array.isArray(gradeList) && gradeList.length === 0) {
        await this.$store.dispatch('courses/queryGradeList', { $axios: this.$axios })
      }
    },

    /**
     * 获取文案配置
     */
    getConfig() {
      let requiredError = 'The input is required.'
      let emailError = 'The Email address seems invalid.'
      let submitName = 'Submit'
      let agreementNotice = 'Sign up for our mailing list to receive the latest news, promotions and updates!'
      let successNotice = 'Thank you for your interest in Think Academy!'
      if (this.locale === 'hk') {
        submitName = '提交'
        agreementNotice = '我想接收學而思最新資訊，包括最新課程資料、學習資源、試題詳解等。'
        successNotice = '提交成功，感謝您對學而思的信任!'
        requiredError = '請輸入此必填項'
        emailError = '請輸入有效的郵箱地址'
      }
      const rules = {
        firstName: [
          { required: true, message: requiredError, trigger: 'blur' },
        ],
        lastName: [
          { required: true, message: requiredError, trigger: 'blur' },
        ],
        name: [
          { required: true, message: requiredError, trigger: 'blur' },
        ],
        email: [
          { required: true, message: requiredError, trigger: 'blur' },
          {
            pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/,
            message: emailError,
            trigger: 'blur',
            formatter: (value) => value.trim(),
          },
        ],
        gradeId: [
          { required: true, message: requiredError, trigger: 'change' },
        ],
      }
      return {
        // 提交按钮名称
        submitName,
        // 协议提示
        agreementNotice,
        // 提交成功提示
        successNotice,
        // 表单验证规则
        rules,
      }
    },
  },
}
