export default {
  props: {
    // 栏目标题
    title: {
      default: '',
      type: String,
    },
    // 栏目介绍
    describe: {
      default: '',
      type: String,
    },
  },
}
