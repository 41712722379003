export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 栏目标题
    columnTitle: {
      default: '',
      type: String,
    },
    // 更多标题
    moreName: {
      default: '',
      type: String,
    },
    // 更多链接
    moreUrl: {
      default: '',
      type: String,
    },
    // 是否大标题
    isBigTitle: {
      default: false,
      type: Boolean,
    },
    // 链接打开方式
    target: {
      default: '',
      type: String,
    },
  },
  methods: {
    moreClick() {
      this.$emit('more-click')
    },
  },
}
