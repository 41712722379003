<template>
  <div>
    <div class="header">
      <p v-if="title" class="title">
        {{ title }}
      </p>
      <p v-if="description" class="intro">
        {{ description }}
      </p>
    </div>
    <div v-if="bannerListLength > 0" class="banner-configruable-wrapper">
      <el-carousel ref="configruableBanners" :interval="5000" arrow="never" :height="bannerHeight" indicator-position="none" class="banner-carousel">
        <el-carousel-item v-for="(item, index) in dataList" :key="index">
          <div class="banner-img">
            <a v-if="item.linkUrl && item.linkUrl !== 'null'" class="img-link" :href="item.linkUrl" :target="item.target?'_blank':''" @click="cardTrackReport">
              <img :data-not-lazy="true" v-lazy-load :data-src="item.webImage" width="1240" height="500" :alt="item.webAltInfo">
            </a>
            <div v-else-if="item.linkUrl === 'null'">
              <img :data-not-lazy="true" v-lazy-load :data-src="item.webImage" width="1240" height="500" :alt="item?.webAltInfo || ''">
            </div>
            <div v-else class="img-link" @click="handleEvaluation">
              <img :data-not-lazy="true" v-lazy-load :data-src="item.webImage" width="1240" height="500" :alt="item?.webAltInfo || ''">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-if="bannerListLength > 1" class="carousel-indicator-container">
        <div class="button-prev" @click="handlePrev">
          <div class="iconfont icon-prev2" />
        </div>
        <div class="button-next" @click="handleNext">
          <div class="iconfont icon-next2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from './mixin';
import trackReportMixin from '../Common/CommonMixin/trackReport'
import bus from 'common/bus'

export default {
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      // 轮播图高度
      bannerHeight: '500px',
    }
  },
  methods: {
    handlePrev() {
      this.$refs.configruableBanners.prev()
    },
    handleNext() {
      this.$refs.configruableBanners.next()
    },
    handleEvaluation() {
      // 埋点上报
      this.buttonTrackReport({
        buttonName: 'toShowDiagnosis',
      });
      if (!this.isLogin) {
        bus.$emit('login', { triggerSource: 'appointment' });
        return
      }
      bus.$emit('toShowDiagnosis')
    },
  },
}
</script>

<style lang="scss" scoped>

.header {
  margin: 0 auto;
  width: 1240px;
  text-align: center;

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 24px;
    font-weight: bold;
    color: #222;
    margin-bottom: 20px;
    line-height: 30px;
  }

  .intro {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #222;
    line-height: 20px;
  }
}

.banner-configruable-wrapper {
  position: relative;
  margin: 0 auto;
  width: 1240px;

  .banner-carousel {
    border-radius: 16px;
    margin-top: 20px;
    // 修复safari轮播图切换过程中圆角失效
    -webkit-transform: rotate(0deg);
  }

  /deep/.el-carousel__item {
    width: 100%;
    height: inherit;
    border-radius: 16px;

    img {
      width: 100%;
      border-radius: 16px;
    }

    .banner-img {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      font-size: 0;
      border-radius: 16px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      a {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .img-link {
        border-radius: 16px;
        cursor: pointer;
      }
    }
  }

  .carousel-indicator-container {
    display: none;

    .button-prev {
      left: 40px;
    }

    .button-next {
      right: 40px;
    }

    .button-prev,
    .button-next {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 10;
      top: 220px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &:hover {
    .carousel-indicator-container {
      display: block;
    }
  }
}
</style>
