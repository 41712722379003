<template>
  <div class="image-list-wrapper">
    <ul v-if="hasWeb" class="image-list">
      <li v-for="(item, index) in dataList" :key="'web-'+index" class="image-item">
        <a v-if="item.linkUrl" :href="item.linkUrl" :target="item.target">
          <img v-lazy-load :data-src="item.webImage" alt="" @click="cardTrackReport" width="100%" height="auto">
        </a>
        <img v-else v-lazy-load :data-src="item.webImage" alt="" width="100%" height="auto">
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * 通用图片组件
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'

export default {
  name: 'ImageList',
  mixins: [mixin, trackReportMixin],
}
</script>
<style lang="scss" scoped>
@import './common.scss';

.image-list-wrapper .image-list {
  width: 1240px;
  margin: auto;
  border-radius: 16px;
}
</style>
