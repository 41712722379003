<template>
  <div class="wrapper container">
    <div v-for="(item, index) in moduleList" :key="index" :class="{'isTwoItem': moduleList.length === 2 }" class="container-item">
      <ModuleMoreHeader
        :column-title="item.columnTitle" :more-name="item.moreName" :more-url="item.moreUrl"
        :target="getLinkTarget(item.openType)"
        @more-click="buttonTrackReport({ buttonName: item.moreName })"
      />
      <template v-if="item.styleType === 'bigPicture'">
        <div class="split-line" />
        <DiffLinkLabel :url="item.linkUrl" :target="getLinkTarget(item.openType)">
          <div class="bigPicture" @click="cardTrackReport({ cardName: item.title })">
            <img v-lazy-load :data-src="item.imageUrl" alt="" :width="bigPictureStyle.width" :height="bigPictureStyle.height" v-lazy-load>
            <div class="big-title text-overflow-ellipsis-2">
              {{ item.title }}
            </div>
            <div class="describe text-overflow-ellipsis-6">
              {{ item.describe }}
            </div>
          </div>
        </DiffLinkLabel>
      </template>
      <template v-if="item.styleType === 'smallPicture'">
        <div v-for="(item1, index1) in getLimiteData(item.dataList, item.styleType)" :key="index1" class="smallPicture">
          <div class="split-line" />
          <DiffLinkLabel :url="item1.linkUrl" :target="getLinkTarget(item1.openType)">
            <div class="small-content" @click="cardTrackReport({ cardName: item1.title })">
              <img :class="{'twoSmall': moduleList.length === 2 }" v-lazy-load :data-src="item1.imageUrl" alt="" width="141" height="91" v-lazy-load>
              <div class="samll-wraper">
                <div class="small-title text-overflow-ellipsis-2">
                  {{ item1.title }}
                </div>
                <div class="small-desc text-overflow-ellipsis-3">
                  {{ item1.describe }}
                </div>
              </div>
            </div>
          </DiffLinkLabel>
        </div>
      </template>
      <template v-if="item.styleType === 'materialDownload'">
        <div class="split-line" />
        <div v-for="(item2, index2) in getLimiteData(item.dataList, item.styleType)" :key="index2" class="materialDownload">
          <div :class="`type-icon ${fileTypeMap[item2.formatType]}`" />
          <div class="material-title text-overflow-ellipsis-2" :class="{'twoMaterial': moduleList.length === 2 }">
            {{ item2.resourceTitle }}
          </div>
          <div class="iconfont icon-download2" @click="getResource(item2.resourceId); cardTrackReport({ cardName: item2.resourceTitle })" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/**
 * 资讯组件
 */
import mixin from './mixin'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleMoreHeader from '../Common/ModuleMoreHeader/web.vue'
import DiffLinkLabel from '../Common/DiffLinkLabel/web.vue'
import { oneApi } from 'config/api'
import bus from 'common/bus'
import { s3Download } from 'common/utils.js';

export default {
  name: 'NewsGroup',
  components: {
    ModuleMoreHeader,
    DiffLinkLabel,
  },
  mixins: [mixin, linkTargetMixin, trackReportMixin],
  computed: {
    bigPictureStyle() {
      if (this.moduleList.length > 2) {
        return {
          width: '377',
          height: '213',
        }
      }
      return {
        width: '580',
        height: '327',
      }
    },
  },
  methods: {
    getLimiteData(dataList, styleType) {
      const sliceLen = styleType === 'smallPicture' ? 3 : 4
      return dataList.slice(0, sliceLen)
    },
    async getResource(id) {
      console.log('PC邮箱4')
      if (!this.isLogin) {
        bus.$emit('login', {triggerSource: 'down'} );
        return
      }
      const info = await this.$axios.post(oneApi.queryUserBasicInfo, { fillInAttention: true }, { rewritePostBody: true })
      if (!info.data.email) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'email',
        })
        return
      }
      if (!info.data.phone) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'phone',
        })
        return
      }
      const res = await this.$axios.post(oneApi.getUrl, id, { rewritePostBody: true });
      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg);
        return
      }
      const resData = res.data || {}
      s3Download(resData.signedUrl)
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;

  .container-item {
    width: 377px;
    max-width: 580px;
  }

  .isTwoItem {
    flex: 1;
  }

  .split-line {
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .head-title {
      font-size: 16px;
      font-weight: bold;
    }

    .head-more {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: var(--txt-color-link);

      .icon-more-right2 {
        font-size: 12px;
        transform: scale(0.6);
      }
    }
  }

  .bigPicture {
    img {
      width: 100%;
      height: 100%;
    }

    .big-title {
      margin: 10px 0;
      font-size: 16px;
      font-weight: bold;
    }

    .describe {
      font-size: 12px;
      font-weight: 400;
      word-break: break-word;
    }
  }

  .smallPicture {
    margin-bottom: 20px;

    .small-content {
      display: flex;

      .samll-wraper {
        margin-left: 15px;

        .small-title {
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
        }

        .small-desc {
          margin-top: 10px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }

    img {
      width: 141px;
      height: 91px;
    }

    .twoSmall {
      width: 161px;
    }
  }

  .materialDownload {
    display: flex;
    align-items: center;
    padding: 18px 30px;
    margin-bottom: 16px;
    background: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #e6e6e6;

    .material-title {
      width: 227px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin: 0 30px 0 10px;
      cursor: default;
    }

    .twoMaterial {
      width: 395px;
      margin: 0 38px 0 30px;
    }

    .icon-download2 {
      flex-shrink: 0;
      font-size: 20px;
      cursor: pointer;
    }

    .type-icon {
      flex-shrink: 0;
      width: 34px;
      height: 40px;
      background: #fff5f4;
      overflow: hidden;

      &.pdf {
        background: #fff5f4  url("../Common/ResourceItem/assets/imgs/icon_pdf.png") no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }

      &.word {
        background: #f3f7ff url("../Common/ResourceItem/assets/imgs/icon_word.png") no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }

      &.excel {
        background: #f0fcf8 url("../Common/ResourceItem/assets/imgs/icon_excel.png") no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }

      &.mp4 {
        background: #fff9ec url("../Common/ResourceItem/assets/imgs/icon_mp4.png") no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }
    }
  }
}
</style>
