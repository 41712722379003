<template>
  <div v-if="bannerListLength > 0" class="banner-configruable-wrapper">
    <div class="banner-configruable-container">
      <el-carousel ref="configruableBanners" :interval="5000" arrow="never" :height="bannerHeight" indicator-position="none" class="banner-carousel" @change="imageChangeHandler">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <!-- 样式一 不带按钮，图片可点击 -->
          <template v-if="getResourceType(item) == 1">
            <div class="banner-img" :style="{'backgroundImage':`url(${item.src})`}">
              <a v-if="item.url" class="img-link" :href="item.url" :target="item.target?'_blank':''" @click="cardTrackReport" />
            </div>
          </template>
          <!-- 样式二 带按钮，图片不可点击 -->
          <template v-else>
            <div class="banner-img" :style="{'backgroundImage':`url(${item.src})`}">
              <div v-if="'widgets' in item" class="btns-group">
                <a v-for="(btn, btnIndex) in Array.prototype.slice.call(item.widgets || {}, 0, 2)" :key="btnIndex"
                   class="button button-small button-banner"
                   :class="{'button-banner-primary': btnIndex == 0,'button-outline': btnIndex == 1, 'button-banner-default': btnIndex == 1}"
                   :href="btn.url" :target="btn.target ? '_blank' : ''"
                   @click="buttonTrackReport({ buttonName: btn.title })"
                >
                  {{ btn.title }}
                </a>
              </div>
            </div>
          </template>
        </el-carousel-item>
      </el-carousel>
      <div v-if="bannerListLength > 1" class="carousel-indicator-container">
        <ul class="carousel-indicator-list">
          <li v-for="index in bannerListLength" :key="index" class="carousel-indicator-item hover-transition" :class="{'actived': activedIndex+1 == index}"
              @mouseenter="changeImage(index)"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';
import trackReportMixin from '../Common/CommonMixin/trackReport'

export default {
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      // 轮播图高度
      bannerHeight: '405px',
    }
  },
  methods: {
    changeImage(index) {
      this.$refs.configruableBanners.setActiveItem(index - 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.banner-configruable-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 0 0 32px 32px;
  background: var(--txt-color-link);
  z-index: 1;

  .banner-configruable-container {
    position: relative;
    margin: 0 auto;
    width: 1240px;
  }

  .banner-carousel {
    border-radius: 16px;
    // 修复safari轮播图切换过程中圆角失效
    -webkit-transform: rotate(0deg);
  }

  /deep/.el-carousel__item {
    width: 100%;
    height: inherit;
    border-radius: 16px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }

    .banner-img {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      font-size: 0;
      border-radius: 16px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      a {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .img-link {
        border-radius: 16px;
      }

      .btns-group {
        position: absolute;
        left: 50px;
        bottom: 60px;
        display: flex;
        z-index: 1;

        .button:first-child {
          margin-right: 20px;
        }

        .button.button-banner {
          display: block;
          padding: 14px 18px 13px;
          width: fit-content;
          min-width: 170px;
        }

        .button-banner-primary {
          background: var(--txt-color-link);

          &:not([disabled]):hover {
            color: #fff;
            background: var(--txt-color-link2);
          }
        }

        .button-banner-default {
          border-color: var(--txt-color-link);
          color: var(--txt-color-link);

          &:not([disabled]):hover {
            border-color: transparent;
            color: #fff;
          }
        }
      }
    }
  }

  .carousel-indicator-container {
    width: 1240px;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .carousel-indicator-list {
      display: flex;
      list-style: none;
      padding: 0;

      .carousel-indicator-item {
        margin: 0 5px;
        width: 30px;
        height: 8px;
        cursor: pointer;

        &::after {
          content: ' ';
          display: block;
          height: 2px;
          margin-top: 3px;
          border-radius: 24px;
          background: rgba(255, 255, 255, 0.4);
        }

        &.actived::after {
          background: #fff;
        }
      }
    }
  }
}

</style>
