export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 组件标题
    title: {
      default: '',
      type: String,
    },
    // 组件数据列表
    dataList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      answerHeight: [],
      imgHeight: [],
    }
  },
  methods: {
    changeAnswerStatus(index) {
      const curHeight = this.answerHeight[index] ? '' : `${this.$refs[`fagAnswer${index}`][0].offsetHeight + (this.imgHeight[index] ? this.imgHeight[index] + 20 : 0)}px`
      this.$set(this.answerHeight, index, curHeight)
    },
    imgLoaded(index) {
      this.$nextTick(() => {
        this.$refs[`fagPic${index}`] && this.$set(this.imgHeight, index, this.$refs[`fagPic${index}`][0].offsetHeight)
      })
    },
  },
}
