export default {
  props: {
    // 资源类型
    resourceType: {
      default: 1,
      type: Number,
    },
    // 资源ID
    resourceId: {
      default: '',
      type: String,
    },
    // 资源名称
    resourceName: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      fileTypeMap: {
        1: 'pdf',
        2: 'word',
        3: 'excel',
        4: 'mp4',
      },
    }
  },
  computed: {
    resourceRealType() {
      return this.fileTypeMap[this.resourceType]
    },
  },
  methods: {
    handleClick() {
      const eventName = this.resourceRealType === 'mp4' ? 'play' : 'download'
      this.$emit(eventName, { id: this.resourceId, type: this.resourceRealType, name: this.resourceName })
    },
  },
}
