export default {
  props: {
    imageUrl: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    describe: {
      default: '',
      type: String,
    },
  },
}
