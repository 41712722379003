<template>
  <div class="map-wrapper">
    <div class="header">
      <p v-if="title" class="title">
        {{ title }}
      </p>
      <p v-if="description" class="intro">
        {{ description }}
      </p>
    </div>
    <div class="content">
      <div class="left" v-html="leftContent" />
      <div class="right">
        <iframe
          v-if="!userNetworkInfo.isMainland && !userNetworkInfo.isChina && userNetworkInfo.isMainland !== null"
          class="map-container"
          width="620"
          height="400"
          style="border: 0; border-radius: 20px;"
          loading="lazy"
          v-lazy-load :data-src="mapUrl"
        />
        <img v-else class="map-img" v-lazy-load :data-src="imgUrl" v-lazy-load>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from './mixin';

export default {
  mixins: [mixin],
  data() {
    const { userNetworkInfo } = this.$store.state
    return {
      isChNetwork: false,
      userNetworkInfo,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.map-wrapper {
  width: 1240px;
  margin: 0 auto;

  .header {
    text-align: center;

    .title {
      color: #222;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
    }

    .intro {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;

    .map-img {
      width: 620px;
      height: 400px;
      border-radius: 20px;
    }
  }
}
</style>
