<template>
  <div class="wrapper">
    <ModuleTextHeader :title="title" :describe="describe" />
    <div :class="['ad2-content', 'ad-content' + styleType]">
      <DiffLinkLabel
        v-for="(item, index) in dataList" :key="index"
        :url="item.linkUrl"
        :target="styleType !== 2 ? (item.openType === 1 ? '_blank' : '_self') : linkTarget"
      >
        <div :class="['ad2-wraper', 'ad' + styleType]" @click="cardTrackReport({ cardName: item.title })">
          <div class="img-wraper">
            <img v-lazy-load :data-src="item.imageUrl" alt="" v-lazy-load>
          </div>
          <div class="text-overflow-ellipsis-2 item-title">
            {{ item.title }}
          </div>
          <div class="item-desc">
            {{ item.describe }}
          </div>
        </div>
      </DiffLinkLabel>
    </div>
    <div v-if="buttonName" class="ad2-bottom">
      <DiffLinkLabel :url="buttonUrl" :target="linkTarget">
        <div class="button btn-wraper" @click="buttonTrackReport({ buttonName })">
          <div class="ad2-btn text-overflow-ellipsis">
            {{ buttonName }}
          </div>
        </div>
      </DiffLinkLabel>
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（二）
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleTextHeader from '../Common/ModuleTextHeader/web.vue'
import DiffLinkLabel from '../Common/DiffLinkLabel/web.vue'

export default {
  name: 'Ad2',
  components: {
    ModuleTextHeader,
    DiffLinkLabel,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
}
</script>
<style lang="scss" scoped>
.ad2-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0 40px;

  .ad2-wraper {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;

    .img-wraper {
      overflow: hidden;
      width: 285px;
      height: 146px;
      margin-bottom: 20px;
    }

    img {
      width: 285px;
      height: 146px;
    }

    .item-title {
      max-width: 285px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: var(--txt-color-lv1);
      line-height: 20px;
    }

    .item-desc {
      margin-top: 10px;
      max-width: 285px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: var(--txt-color-lv1);
      word-break: break-word;
      line-height: 18px;
    }
  }

  .ad8 {
    border-radius: 16px;
    border: 1px solid #f1f1f1;
    padding: 20px;
    height: 100%;
    justify-content: flex-start;

    .img-wraper,
    img {
      width: 360px;
      height: 200px;
      border-radius: 8px 8px 0 0;
    }

    .item-title {
      font-size: 20px;
      line-height: 24px;
    }

    .item-desc {
      font-size: 16px;
      line-height: 20px;
    }

    .item-title,
    .item-desc {
      max-width: 360px;
      width: 360px;
      text-align: left;
    }
  }

  a .ad8:hover {
    border: 1px solid #ffebcc;

    .item-title,
    .item-desc {
      color: var(--txt-color-link);
    }
  }

  a .ad9:hover {
    .img-wraper {
      transform: scale(1.02);
      box-shadow: 0 8px 23px 9px rgba(76, 78, 82, 0.15);
    }

    .item-title,
    .item-desc {
      color: var(--txt-color-link);
    }
  }

  .ad9 {
    padding: 0;

    .img-wraper,
    img {
      width: 400px;
      height: 295px;
      border-radius: 16px;
    }

    .item-title {
      font-size: 20px;
      line-height: 24px;
    }

    .item-desc {
      font-size: 16px;
      line-height: 20px;
    }

    .item-title,
    .item-desc {
      max-width: 400px;
      width: 400px;
      text-align: left;
    }
  }
}

.ad-content8 {
  gap: 15px;
  padding-top: 20px;
}

.ad-content9 {
  gap: 20px;
  padding-top: 20px;
}

.ad2-bottom {
  margin: 0 auto;
  max-width: 216px;
  display: flex;
  justify-content: center;

  .btn-wraper {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    border-radius: 70px;
    background: var(--txt-color-link);

    .ad2-btn {
      max-width: 222px;
      min-width: 137px;
      padding: 12px 15px;
    }

    &:hover {
      background: var(--txt-color-link2);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
</style>
