<template>
  <div class="resource-item">
    <div class="video-img" @click="handlePlay({id: dataItem.resourceList[0].resourceId, name: dataItem.resourceList[0].buttonName})">
      <img class="img" :data-not-lazy="true" v-lazy-load :data-src="dataItem.resourceList[0].videoImg">
      <span class="icon-btn" />
      <p class="duration">
        {{ formatTime(dataItem.resourceList[0].videoDuration) }}
      </p>
    </div>
    <p class="video-title">
      {{ dataItem.resourceTitle }}
    </p>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  props: {
    showNum: {
      type: Number,
      default: 1,
    },
    // 数据列表
    dataList: {
      default: () => [],
      type: Array,
    },
    showMore: {
      default: true,
      type: Boolean,
    },
    dataItem: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      clickShowMore: false,
    }
  },
  computed: {
    dataArr() {
      console.log('showNum', this.showNum, this.dataList, this.dataList.slice(0, this.showNum))
      return this.showMore ? this.dataList.slice(0, this.showNum) : this.dataList
    },
  },
  methods: {
    handlePlay(...obj) {
      this.$emit('play', ...obj)
    },
    formatTime(secs) {
      const time = moment.duration(secs, 'milliseconds')
      return moment({ h: time.hours(), m: time.minutes(), s: time.seconds() }).format('HH:mm:ss')
    },
  },
}
</script>
<style lang="scss" scoped>

.video-wrapper {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}

.more {
  cursor: pointer;
  margin-top: 32px;
  text-align: center;
  color: var(--txt-color-lv1);
  font-size: 14px;

  .arrow-down {
    margin-right: 6px;
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: -2px;
    background: center/100% no-repeat url(./assets/imgs/more.png);
  }

  &:hover {
    color: var(--txt-color-link);

    .arrow-down {
      background: center/100% no-repeat url(./assets/imgs/more-hover.png);
    }
  }

  &.less {
    .arrow-down {
      transform: rotate(180deg);
    }
  }
}

.resource-item {
  width: 260px;
  margin-top: 40px;

  .video-img {
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    .img {
      width: 260px;
      height: 146px;
      vertical-align: top;
    }

    .duration {
      position: absolute;
      bottom: 0;
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
      width: 100%;
      height: 38px;
      font-size: 14px;
      color: #fff;
      text-align: right;
      line-height: 42px;
      padding-right: 10px;
    }
  }

  .video-title {
    width: 260px;
    margin-top: 12px;
    font-size: 16px;
    color: var(--txt-color-lv1);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
}

.icon-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
  background: url('./assets/imgs/video-play.png') no-repeat;
  background-size: 100%;
}

.video-img:hover .icon-btn {
  background: url('./assets/imgs/video-play-hover.png') no-repeat;
  background-size: 100%;
}
</style>
