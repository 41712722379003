<template>
  <div class="suction_bottom">
    <div class="wrapper">
      <div class="description">
        {{ copywriting }}
      </div>
      <div class="button_content">
        <div v-for="(item, index) in dataList" :key="index" class="button btn-primary" @click="handleAccept(item)">
          {{ item.buttonCopywriting }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';
import bus from 'common/bus'
import trackReportMixin from '../Common/CommonMixin/trackReport'

export default {
  mixins: [mixin, trackReportMixin],
  data() {
    return {
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin;
    },
  },
  methods: {
    handleAccept(item) {
      console.log(item)
      if (item.skip === '_blank') { // 功能性跳转
        // 埋点上报
        this.buttonTrackReport({
          buttonName: 'toShowDiagnosis',
        });
        if (!this.isLogin) {
          // bus.$emit('login', {triggerSource: 'diagnosis'});
          bus.$emit('login', { triggerSource: 'appointment' });
          return
        }
        // bus.$emit('toShowDiagnosis', { type: 1})
        bus.$emit('toShowDiagnosis')
      }
      if (item.skip === '_self') { // 自定义链接跳转
        if (item.skipWay === '_blank') { // 在当前页面打开
          window.location.href = item.skipLink;
        }
        if (item.skipWay === '_self') { // 在新标签页打开
          window.open(item.skipLink, '_blank');
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .suction_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 64px;
    background: #ff9f0a;

    .wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    .description {
      max-width: 980px;
      line-height: 64px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
      font-family: 'Montserrat-SemiBold', 'Montserrat', sans-serif;
      font-weight: 600;
      color: #fff;
    }

    .button_content {
      display: flex;
    }

    .button {
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 20px;
      cursor: pointer;
      margin-top: 12px;
      background: #fff;
      font-size: 16px;
      font-family: 'PingFangSC-Semibold', 'PingFang SC', sans-serif;
      font-weight: 600;
      color: #ff9f0a;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 49px;
      padding-right: 49px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
</style>
