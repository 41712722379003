<template>
  <div class="number-scroll-wrapper wrapper" :style="backgroundImageStyle">
    <div class="container">
      <div v-if="title" class="title text-overflow-ellipsis-2">
        {{ title }}
      </div>
      <div v-if="description" class="description text-overflow-ellipsis-2">
        {{ description }}
      </div>
      <div class="content" :class="columnClassName">
        <div v-for="(item, index) in countList" :key="index" class="item">
          <div class="item-head">
            <div class="num">
              <ICountUp
                :delay="item.delay"
                :end-val="item.endVal"
                :options="item.options"
              />
            </div>
            <div class="unit">
              {{ item.unit }}
            </div>
          </div>
          <div class="item-describe text-overflow-ellipsis-2">
            {{ item.describe }}
          </div>
        </div>
      </div>
      <div v-if="buttonName" class="button-wrapper">
        <DiffLinkLabel :url="linkUrl" :target="linkTarget">
          <div class="btn btn-primary" @click="buttonTrackReport({ buttonName })">
            {{ buttonName }}
          </div>
        </DiffLinkLabel>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 数字滚动（Web组件）
 */
import ICountUp from 'vue-countup-v2'
import mixin from './mixin'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import DiffLinkLabel from '../Common/DiffLinkLabel/web.vue'

export default {
  name: 'NumberScroll',
  components: {
    ICountUp,
    DiffLinkLabel,
  },
  mixins: [mixin, linkTargetMixin, trackReportMixin],
  computed: {
    columnClassName() {
      return `column-${this.dataList.length}`
    },
    backgroundImageStyle() {
      if (!this.webBackgroundImage) return {}
      return {
        backgroundImage: `url('${this.webBackgroundImage}')`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.number-scroll-wrapper {
  display: flex;
  align-items: center;
  height: 400px;
  background-image: url('./assets/bg-web.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;

  .container {
    width: 100%;
  }

  .title {
    width: 800px;
    line-height: 30px;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .description {
    width: 800px;
    line-height: 20px;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  .content {
    display: flex;
    margin-top: 40px;

    &.column-3 {
      .num {
        font-size: 50px;
      }

      .unit {
        margin-top: 28px;
      }
    }

    &.column-4 {
      .num {
        font-size: 40px;
      }

      .unit {
        margin-top: 19px;
      }
    }

    .item {
      flex: 1;
    }

    .item-head {
      display: flex;
      color: #ff7709;
      justify-content: center;
      font-weight: bold;
    }

    .item-describe {
      padding: 0 20px;
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      color: var(--txt-color-lv1);
    }

    .unit {
      margin-left: 2px;
      font-size: 20px;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .btn {
      display: inline-block;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      padding: 0 24px;
      font-weight: 400;
    }
  }
}
</style>
