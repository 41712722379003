export default {
  props: {
    // 组件名称
    componentName: {
      default: 'NumberScroll',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 标题
    title: {
      default: '',
      type: String,
    },
    // 栏目简介
    description: {
      default: '',
      type: String,
    },
    // Web图片背景地址
    webBackgroundImage: {
      default: '',
      type: String,
    },
    // Touch图片背景地址
    touchBackgroundImage: {
      default: '',
      type: String,
    },
    // 按钮名称
    buttonName: {
      default: '',
      type: String,
    },
    // 按钮链接跳转地址
    linkUrl: {
      default: '',
      type: String,
    },
    // 滚动数据列表
    dataList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    const countList = []
    this.dataList.forEach((item) => {
      countList.push({
        delay: 100,
        endVal: Number(item.num) || 0,
        options: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
        },
        unit: item.unit,
        describe: item.describe,
      })
    })
    return {
      countList,
    }
  },
  methods: {},
}
