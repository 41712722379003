export default {
  props: {
    // 组件名称
    componentName: {
      default: 'generalImgTwo',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 自定义组件内容
    dataList: {
      default: [],
      type: Array,
    },
    // 主题
    columnTheme: {
      default: '',
      type: String,
    },
    // 简介
    ColumnIntroduction: {
      default: '',
      type: String,
    },
    group: {
      default: [],
      type: Array,
    },
  },
  computed: {
    hasWeb() {
      return this.group.includes('Web')
    },
    hasTouch() {
      return this.group.includes('Touch')
    },
  },
}
