<template>
  <div class="custom-button-wrapper">
    <div class="custom-button-component" v-if="skinType === 1">
      <div class="button-content" @click="toGn(buttonName)">
        <div v-if="iconPosition === 'left' && iconUrl && normalDisplay" class="button-icon left-icon">
          <img v-lazy-load :data-src="iconUrl" :alt="buttonName" @error="failRendering" v-lazy-load>
        </div>
        <div class="button-text">
          {{ buttonName }}
        </div>
        <div v-if="iconPosition === 'right' && iconUrl && normalDisplay" class="button-icon right-icon">
          <img v-lazy-load :data-src="iconUrl" :alt="buttonName" @error="failRendering" v-lazy-load>
        </div>
      </div>
    </div>

    <a :href="linkUrl" :target="target" class="custom-button-component" v-else>
      <div class="button-content" @click="buttonTrackReport({ buttonName })">
        <div v-if="iconPosition === 'left' && iconUrl && normalDisplay" class="button-icon left-icon">
          <img v-lazy-load :data-src="iconUrl" :alt="buttonName" @error="failRendering" v-lazy-load>
        </div>
        <div class="button-text">
          {{ buttonName }}
        </div>
        <div v-if="iconPosition === 'right' && iconUrl && normalDisplay" class="button-icon right-icon">
          <img v-lazy-load :data-src="iconUrl" :alt="buttonName" @error="failRendering" v-lazy-load>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
/**
 * 按钮组件
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import bus from 'common/bus'

export default {
  name: 'CustomButton',
  mixins: [mixin, trackReportMixin],
  methods: {
    toGn( buttonName ) {
      this.buttonTrackReport({ buttonName })
      if (!this.isLogin) {
        bus.$emit('login', { triggerSource: 'appointment' });
        return
      }
      bus.$emit('toShowDiagnosis')
    },
  },
}
</script>
<style lang="scss" scoped>
@import './common.scss';

.custom-button-wrapper .custom-button-component {
  width: 392px;
}
</style>
