<template>
  <div class="evaluation-entry-wrapper wrapper">
    <div class="header-wrapper">
      <ModuleMoreHeader
        :component-name="componentName" :component-type="componentType" :component-title="componentTitle" :column-title="title"
      />
    </div>
    <div class="content-wrapper">
      <div class="button-group">
        <div class="group-wrapper">
          <div class="title-wrapper">
            <div class="icon-write" />
            <div class="title">
              {{ $t('evaluation.name') }}
            </div>
          </div>
          <div class="arrow-right-wrapper" @click="handleToEvaluation">
            <div class="iconfont icon-reschedule" />
          </div>
        </div>
      </div>
      <div class="description-wrapper">
        <div class="description">
          {{ description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 诊断入口组件（Web组件）
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleMoreHeader from '../Common/ModuleMoreHeader/web'

export default {
  name: 'EvaluationEntry',
  components: {
    ModuleMoreHeader,
  },
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.evaluation-entry-wrapper {
  .header-wrapper {
    margin-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
  }

  .content-wrapper {
    display: flex;
    border: 3px solid #eaedf0;
    border-radius: 12px;
    overflow: hidden;
  }

  .button-group {
    display: flex;
    align-items: center;
    width: 737px;
    padding: 29px 30px;

    .group-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;

      .icon-write {
        width: 60px;
        height: 60px;
        margin-right: 12px;
        background: url('./assets/icon-write.png') no-repeat;
        background-size: 100%;
      }

      .title {
        font-size: 24px;
        color: var(--txt-color-lv1);
        line-height: 60px;
      }
    }

    .arrow-right-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin-top: 10px;
      border-radius: 50%;
      background: var(--txt-color-link);
      cursor: pointer;

      .icon-reschedule {
        font-size: 24px;
        color: #fff;
      }
    }
  }

  .description-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 30px;
    background: #f7f9fb;
    font-size: 18px;
    color: var(--txt-color-lv1);
    line-height: 20px;
  }
}
</style>
