<template>
  <div class="wrapper">
    <ModuleMoreHeader
      :component-name="componentName" :component-type="componentType" :component-title="componentTitle"
      :column-title="title" :more-name="buttonName" :is-big-title="true" :more-url="buttonUrl"
      :target="linkTarget"
      @more-click="buttonTrackReport({ buttonName })"
    />
    <div class="split-line" />
    <div class="desc">
      {{ describe }}
    </div>
    <div class="ad6-wrapper" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave">
      <div ref="mySwiper" v-swiper:mySwiper="swiperOption" class="my-swiper">
        <div class="my-swiper-container swiper-wrapper">
          <div v-for="(item, index) in dataList" :key="`${index}swiper`" class="swiper-slide" @click="cardTrackReport({ cardName: item.sysName })">
            <nuxt-link :to="'/teachers/detail/'+item.teacherId">
              <TextRightImg class="swiper-item" :avatar="item.avatar" :name="item.sysName" :education="item.education" :identity-tag="item.identityTag" :experience="item.experience" />
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（六）
 */
import mixin from './mixin'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleMoreHeader from '../Common/ModuleMoreHeader/web.vue'
import TextRightImg from './TextRightImg/web.vue'

export default {
  name: 'Ad6',
  /* eslint-disable-next-line */
  components: { ModuleMoreHeader, TextRightImg, Swiper, SwiperSlide, },
  directives: {
    swiper: directive,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        lazy:{
          loadPrevNext:true, // 预加载前一个后一个
        },
        preloadImages: false,
        loopAdditionalSlides: 2,
        loopedSlides: 5,
        autoplay: {
          delay: 0,
        },
        speed: 6000,
        spaceBetween: 80,
        freeMode: true,
      },
      slideWidth: 500,
      lastNeedSwiperSpeed: 0,
    }
  },
  methods: {
    handleMouseenter() {
      this.lastNeedSwiperSpeed = (Math.abs(Math.abs(this.mySwiper.getTranslate()) - Math.abs(this.mySwiper.translate)) / (this.slideWidth + this.mySwiper.params.spaceBetween)) * this.mySwiper.params.speed
      this.mySwiper.setTranslate(this.mySwiper.getTranslate())
      this.mySwiper.autoplay.stop()
    },
    handleMouseleave() {
      this.mySwiper.slideTo(this.mySwiper.activeIndex, this.lastNeedSwiperSpeed)
      this.mySwiper.autoplay.start()
    },
  },
}
</script>
<style lang="scss" scoped>
.split-line {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
}

.desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 56px;
}

.my-slider {
  width: 400px;
  margin: 0 auto;
}

.my-swiper {
  width: 1240px;
  height: 240px;

  .my-swiper-container {
    display: flex;
    height: 240px;
    transition-timing-function: linear;

    .swiper-slide {
      width: 500px;
    }

    .swiper-item {
      flex-shrink: 0;
      width: 500px;
      height: 240px;
      display: flex;
      align-items: center;
    }
  }

  .progressbar-wrapper {
    position: relative;
    width: 400px;
    height: 8px;
    margin: 40px auto 0 auto;

    .swiper-progressbar {
      width: 400px;
      height: 8px;
      border-radius: 4px;
      background: #fff0ce;
      overflow: hidden;
    }

    /deep/.swiper-pagination-progressbar-fill {
      background: var(--txt-color-link);
    }
  }
}

/deep/.el-slider {
  .el-slider__runway {
    height: 8px;
    border-radius: 5px;

    .el-slider__bar {
      height: 8px;
      transition: all 5s linear;
    }

    .el-slider__button-wrapper {
      z-index: 10;
      transition: all 5s linear;
    }
  }
}
</style>
