<template>
  <div class="category-container">
    <div class="filter-wrapper font-medium">
      <div v-if="title" class="category-title">
        {{ title }}
      </div>
      <ul v-if="enableGroup" class="category-department-list">
        <li
          v-for="(item, index) in categoryGroup"
          :key="item.id"
          v-sa-track="[{type: 'click', event: 'component_button_click', data: getCategoryClickTrackData(item.name)}]"
          class="hover-transition-text hover-transition-linear category-item"
          :class="{'actived': selectedGroupIndex == index}"
          @click="handleClickGroup(index, item)"
          @mouseenter="addClass(index)"
          @mouseleave="removeClass"
        >
          <img v-if="item.iconUrl" class="icon" v-lazy-load :data-src="`${ (selectedGroupIndex === index || hover === index) ? item.activeIconUrl : item.iconUrl}`" alt="" v-lazy-load>
          <div class="name">
            {{ item.name }}
          </div>
        </li>
      </ul>
      <ul v-if="enableGroup && categoryGroup[selectedGroupIndex] && categoryGroup[selectedGroupIndex].children" class="category-list">
        <li
          v-for="(item, index) in categoryGroup[selectedGroupIndex].children"
          :key="index"
          v-sa-track="[{type: 'click', event: 'component_button_click', data: getCategoryClickTrackData(item.name)}]"
          class="hover-transition-text hover-transition-linear category-item"
          :class="{ 'actived': selectedIndex == index }"
          @click="handleClick(index, item)"
        >
          <div class="name">
            {{ item.name }}
          </div>
        </li>
      </ul>
      <ul v-else class="category-list">
        <li
          v-for="(item, index) in category"
          :key="index"
          v-sa-track="[{type: 'click', event: 'component_button_click', data: getCategoryClickTrackData(item.name)}]"
          class="hover-transition-text hover-transition-linear category-item"
          :class="{ 'actived': selectedIndex == index }"
          @click="handleClick(index, item)"
        >
          <div class="name">
            {{ item.name }}
          </div>
        </li>
      </ul>
    </div>
    <div class="result-wrapper">
      <template v-if="loadingModules">
        <Loading :show="true" :margin-bottom="600" />
      </template>
      <template v-else>
        <template v-if="moduleList.length">
          <DynamicComponent v-if="!!moduleList.length" :style="handleStyles(moduleList)" :locale="locale" :component-list="moduleList" />
        </template>
        <template v-else>
          <div class="exception-wrapper">
            <Exception :tip-text="langContext.emptyNotice" placeholder-class-name="no-avalible-data-img" />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import Loading from 'components/common/Loading'
import Exception from 'components/common/Exception'

export default {
  name: 'Category',
  components: {
    Loading,
    Exception,
    DynamicComponent: () => import('../web'),
  },
  mixins: [mixin],
  mounted() {
    if (this.targetType === 'module') {
      this.initQueryModule()
    }
  },
  methods: {
    handleStyles(list = []) {
      const componentList = list.filter((item) => item.type !== 49)
      const styles = {
        paddingBottom: '0.33333vw',
        background: '#fff',
      }
      if (!componentList.length) {
        return {
          ...styles,
        }
      }
      const firstComponent = componentList[0]
      if (!firstComponent.content) {
        return {
          ...styles,
        }
      }
      const contentData = JSON.parse(firstComponent.content)
      const styleAttr = {}
      if (!contentData.componentName.includes('Banner')) {
        styleAttr.paddingTop = '4.26667vw'
      }
      return {
        ...styleAttr,
        ...styles,
      }
    },
    /**
     * 初始查询模块列表数据
     */
    initQueryModule() {
      let moduleList = []
      try {
        if (this.enableGroup) {
          moduleList = this.categoryGroup[0].children[0].moduleList
        } else {
          moduleList = this.category[0].moduleList
        }
      } catch (error) {
        console.error(error)
      }
      this.queryModuleList(moduleList)
    },

    /**
     * 点击分类组
     * @param {Number} index 分类组索引
     * @param {Object} item 分类级对象
     */
    handleClickGroup(index, item) {
      if (index === this.selectedGroupIndex) return
      this.selectedIndex = 0
      this.selectedGroupIndex = index
      const defaultCategory = item.children[0] || {}
      this.queryModuleList(defaultCategory.moduleList)
    },

    /**
     * 点击分类
     * @param {Number} index 索引
     * @param {Object} item 分类对象
     */
    handleClick(index, item) {
      if (this.targetType === 'page') {
        window.open(item.pageUrl, '_blank')
        return
      }
      if (index === this.selectedIndex) return
      this.selectedIndex = index
      this.queryModuleList(item.moduleList)
    },
  },
}
</script>

<style lang="scss" scoped>
.category-container {
  width: 1240px;
  margin: 0 auto;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 29px 0 9px 0;
  background: #fff;
  border: 1px solid #dee2e7;
  border-radius: 10px;
  margin-bottom: 40px;
}

.category-title {
  margin: 0 auto 20px auto;
  font-size: 18px;
  color: var(--txt-color-lv1);
  font-weight: bold;
}

.category-department-list,
.category-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.category-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 20px 0;
  padding: 8px 16px;
  min-width: 160px;
  max-width: 230px;
  height: 38px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  color: var(--txt-color-link);
  cursor: pointer;
  border: 0.5px solid var(--txt-color-link);
  transition: background 0.2s linear;

  .icon {
    margin-right: 2px;
    width: 24px;
    height: 24px;
  }

  .name {
    max-width: 168px;
    height: 36px;
    line-height: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    text-align: center;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: var(--txt-color-link);
    background: #fff5e6;
  }

  &.actived {
    background: var(--txt-color-link);
    color: #fff;
  }
}

.module-component-wrapper {
  margin-bottom: 40px;
}

.exception-wrapper {
  padding: 50px 0;
}
</style>
