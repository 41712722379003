export default {
  props: {
    // 组件名称
    componentName: {
      default: 'RichTextColumn',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 自定义组件内容
    title: {
      default: '',
      type: String,
    },
    totalGrids: {
      default: 10,
      type: Number,
    },
    dataList: {
      default: [],
      type: Array,
    },
  },
}
