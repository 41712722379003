import { oneApi } from 'config/api'

export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 栏目标题
    title: {
      default: '',
      type: String,
    },
    // 栏目描述
    description: {
      default: '',
      type: String,
    },
    // 是否显示查看更多按钮
    showMore: {
      default: true,
      type: Boolean,
    },
    // 资源类型
    resourceType: {
      default: 'double',
      type: String,
    },
    // 数据列表
    dataList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      langContext: this.$t('dynamicComponents.Resource'),
      expanseHeight: 0, // 展开筛选项的高度
      isExpanse: false, // 是否展开所有筛选项
      allowMaterialsNum: this.$t('common').allowMaterialsNum || 0, // 允许下载的资料数量
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin;
    },
    isShowMore() {
      return this.dataList.length > this.showMoreNum && this.showMore
    },
  },
  methods: {
    async checkUserStatus() {
      console.log('PC邮箱5')
      const info = await this.$axios.post(oneApi.queryUserBasicInfo, { fillInAttention: true }, { rewritePostBody: true })
      if (!info.data.email) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'email',
        })
        return false
      }
      if (!info.data.phone) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'phone',
        })
        return false
      }
      return true
    },
    async getResourceData(id) {
      const webClient = process.env.clientType === 'web'
      const res = await this.$axios.post(oneApi.getUrl, id, { rewritePostBody: true });
      if (Number(res.code) !== 0) {
        if (webClient) {
          this.$ElMessage.error(res.msg);
        } else {
          this.$Toast(res.msg);
        }
        return false
      }
      return res.data || {}
    },
  },
}
