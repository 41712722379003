<template>
  <div class="wrapper">
    <ModuleTextHeader :title="title" :describe="description" />
    <div class="recorded-content">
      <RecordingCard
        v-for="(dataItem, index) in dataList"
        :key="index"
        v-sa-track="[{type: 'visually', event: 'recorded_class_card_show', data: getVisuallyTrackData(dataItem)}]"
        class="item"
        :card-data="dataItem"
        :is-common="true"
        :has-border="true"
        :has-bottom="true"
        :bottom-content="'price'"
        @click.native="cardClickHandler(dataItem)"
      />
    </div>
  </div>
</template>

<script>
/**
 * 录播课栏目
 */
import ModuleTextHeader from '../Common/ModuleTextHeader/web.vue'
import RecordingCard from '@/components/global/FeatureComponents/Goods/RecordedCommonCoursesCard/web'

import mixin from './mixin'

export default {
  name: 'RecordedGoodsColumn',
  components: {
    ModuleTextHeader,
    RecordingCard,
  },
  mixins: [mixin],
}
</script>

<style lang="scss">
.recorded-content {
  display: grid;
  grid-gap: 20px;
  grid-column: 3;
  grid-template-columns: 400px 400px 400px;
  justify-items: start;

  .item {
    width: 400px;
    cursor: pointer;
  }
}
</style>
