<template>
  <div>
    <div v-for="item in renderComponentList" :key="item.key" class="component-wrapper">
      <component
        :is="item.componentName"
        v-sa-track="[{type: 'visually', event: 'component_show', data: getTrackData(item)}]"
        :locale="locale"
        :module-id="item.moduleId"
        :component-name="item.componentName"
        :component-type="item.componentType"
        :component-title="item.componentTitle"
        :registration="item.registration"
        v-bind="item.componentData"
      />
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import Banner from './Banner/web'
import Banner2 from './Banner-2/web'
import Banner3 from './Banner-3/web'
import Category from './Category/web'
import MockExamColumn from './MockExamColumn/web'
import ModuleRichText from './ModuleRichText/web'
import PageRichText from './PageRichText/web'
import GetNotified from './GetNotified/web'
import GetNotified2 from './GetNotified-2/web'
import Application from './Application/web'
import Trustpilot from './Trustpilot/web'
import CarouselComponent from './CarouselComponent/web'
import SuctionBottom from './SuctionBottom/web'
import CourseListComponent from './CourseListComponent/web'
import NewsGroup from './NewsGroup/web'
import UserComments from './UserComments/web'
import Ad1 from './Ad-1/web'
import Ad2 from './Ad-2/web'
import Ad3 from './Ad-3/web'
import Ad4 from './Ad-4/web'
import Ad5 from './Ad-5/web'
import Ad6 from './Ad-6/web'
import Ad7 from './Ad-7/web'
import Ad8 from './Ad-8/web'
import Ad9 from './Ad-9/web'
import CustomerForm from './CustomerForm/web'
import CustomButton from './CustomButton/web'
import ImageList from './ImageList/web'
import generalImgTwo from './generalImgTwo/web'
import UserCommentsBigPicture from './UserCommentsBigPicture/web'
import Faqs from './Faqs/web'
import RichTextColumn from './RichTextColumn/web'
import resourcesColumn from './Resource/web'
import ClassColumn from './ClassColumn/web'
import CoursesColumn1 from './CoursesColumn-1/web'
import CoursesColumn2 from './CoursesColumn-2/web'
import CoursesDetailColumn from './CoursesDetailColumn/web'
import RecordedGoodsColumn from './RecordedGoodsColumn/web'
import CommonGoodsColumn from './CommonGoodsColumn/web'
import NumberScroll from './NumberScroll/web'
import EvaluationEntry from './EvaluationEntry/web'
import Map from './Map/web'
import Dynamic from './Dynamic/web'
import ScoreQueryComponent from './ScoreQueryComponent/web'

export default {
  name: 'DynamicComponent',
  components: {
    // 轮播图组件1
    Banner,
    // 轮播图组件2
    Banner2,
    // 轮播图组件3
    Banner3,
    // 分类组件
    Category,
    // 模拟考试栏目组件
    MockExamColumn,
    // 图文组件（一）
    ModuleRichText,
    // 图文组件（二）
    PageRichText,
    // 留资组件1
    GetNotified,
    // 留资组件2
    GetNotified2,
    // 注册组件
    Application,
    // 轮播组件
    CarouselComponent,
    // 吸底组件
    SuctionBottom,
    // 课程组件
    CourseListComponent,
    // 资讯组
    NewsGroup,
    // 评价组件
    UserComments,
    // Ad-1
    Ad1,
    // Ad-2
    Ad2,
    // Ad-3
    Ad3,
    // Ad-4
    Ad4,
    // Ad-5
    Ad5,
    // Ad-6
    Ad6,
    // Ad-7
    Ad7,
    // Ad-8
    Ad8,
    // Ad-9
    Ad9,
    // 自定义表单组件
    CustomerForm,
    // 自定义按钮组件
    CustomButton,
    // 通用图片组件
    ImageList,
    // 通用图片组件2
    generalImgTwo,
    // 大图评价组件
    UserCommentsBigPicture,
    // Faqs 组件
    Faqs,
    // 富文本分栏组件
    RichTextColumn,
    // 资源组件
    resourcesColumn,
    // 班级栏目组件
    ClassColumn,
    // 课程栏目(一)组件
    CoursesColumn1,
    // 课程栏目(二)组件
    CoursesColumn2,
    // 课程栏目详情组件
    CoursesDetailColumn,
    // 录播课栏目组件
    RecordedGoodsColumn,
    // 通用商品组件
    CommonGoodsColumn,
    // 数字滚动组件
    NumberScroll,
    // 诊断入口组件
    EvaluationEntry,
    // 地图组件
    Map,
    // 动态组件
    Dynamic,
    // 评分组件
    Trustpilot,
    // 成绩查询组件
    ScoreQueryComponent,
  },
  mixins: [mixin],
}
</script>

<style lang="scss" scoped>
.component-wrapper {
  margin-bottom: 60px;

  &:last-child {
    margin-top: 0;
  }
}
</style>
