export default {
  name: 'Banner3',
  props: {
    // 组件名称
    componentName: {
      default: 'Banner3',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    dataList: {
      default: () => ([]),
      type: Array,
    },
    title: {
      default: '',
      type: String,
    },
    description: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      activedIndex: 0,
    }
  },
  computed: {
    bannerListLength() {
      if (!Array.isArray(this.dataList)) return 0
      return this.dataList.length;
    },
    isLogin() {
      return this.$store.state.ucenter.isLogin
    },
  },
  methods: {
    imageChangeHandler(newIndex) {
      this.activedIndex = newIndex
    },
  },
}
