<template>
  <div class="wrapper">
    <ModuleTextHeader :title="title" :describe="describe" />
    <div class="ad5-content">
      <DiffLinkLabel
        v-for="(item, index) in dataList" :key="index"
        :url="item.linkUrl"
        :target="linkTarget"
      >
        <div
          :style="getBackgroundStyle()"
          v-lazy-background :lazy-background="item.imageUrl"
          class="ad5-wraper"
          :class="`content-size-${imageGridNum}`"
          @click="cardTrackReport({ cardName: item.title })"
        >
          <div class="title text-overflow-ellipsis-2">
            {{ item.title }}
          </div>
        </div>
      </DiffLinkLabel>
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（五）
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleTextHeader from '../Common/ModuleTextHeader/web.vue'
import DiffLinkLabel from '../Common/DiffLinkLabel/touch.vue'

export default {
  name: 'Ad5',
  components: {
    ModuleTextHeader,
    DiffLinkLabel,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
}
</script>
<style lang="scss" scoped>
.ad5-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .link-container {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .ad5-wraper {
    width: 285px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #fff;
      line-height: 32px;
      text-align: center;
      padding: 0 50px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
      background-blend-mode: multiply;
    }
  }

  .content-size-2 {
    width: 590px;
    height: 332px;
  }

  .content-size-3 {
    width: 386px;
    height: 217px;
  }
}
</style>
