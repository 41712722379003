export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 栏目标题
    title: {
      default: '',
      type: String,
    },
    // 栏目介绍
    describe: {
      default: '',
      type: String,
    },
    // 每行显示卡片数
    imageGridNum: {
      default: 4,
      type: [String, Number],
    },
    // 内容项列表
    dataList: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    getBackgroundStyle() {
      return {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
      }
    },
  },
}
