export default {
  props: {
    // 组件名称
    componentName: {
      default: 'CarouselComponent',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    theme: {
      default: '',
      type: String,
    },
    themeIntro: {
      default: '',
      type: String,
    },
    layout: {
      default: [],
      type: Array,
    }
  },
}
