export default {
  data() {
    return {
      clickShowMore: false,
    }
  },
  props: {
    // 资源类型
    resourceType: {
      default: 'double',
      type: String,
    },
    // 数据列表
    dataList: {
      default: () => [],
      type: Array,
    },
    showMore: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    classType() {
      return this.resourceType === 'double' ? 'double-wraper' : 'single-wraper'
    },
  },
  methods: {
    handleDownload(...obj) {
      this.$emit('download', ...obj)
    },
    handlePlay(...obj) {
      this.$emit('play', ...obj)
    },
  },
}
