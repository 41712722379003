<template>
  <div class="rich-text-column-wrapper">
    <div v-if="title" class="headline">
      {{ title }}
    </div>
    <div class="content-box" :style="contentBoxStyle">
      <div v-for="(item, index) in dataList" :key="'rich-'+index" class="card-content">
        <div v-html="item.content" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 富文本分栏组件
 */
import mixin from './mixin'

export default {
  name: 'RichTextColumn',
  mixins: [mixin],
  computed: {
    contentBoxStyle() {
      const style = this.dataList.map((item) => `${item.ratio}fr`).join(' ')
      return {
        'grid-template-columns': style,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import './common.scss';

.rich-text-column-wrapper {
  .headline {
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .content-box {
    width: 1240px;
    margin: auto;
    padding: 20px;
    display: grid;
    grid-column-gap: 20px;

    .card-content {
      padding: 20px;
    }
  }
}
</style>
