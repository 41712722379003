export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 栏目标题
    title: {
      default: '',
      type: String,
    },
    // 栏目介绍
    description: {
      default: '',
      type: String,
    },
    // 数据列表
    dataList: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    getVisuallyTrackData(item) {
      return {
        goods_name: item.title,
        goods_id: item.id,
        subject: item.spec?.subject,
        grade: item.spec?.grade,
        content_teacher: (item.spec?.teacherList?.length && item.spec?.teacherList[0].sysName) || '',
        content_teacher_id: (item.spec?.teacherList?.length && item.spec?.teacherList[0].teacherId) || '',
        goods_sell_price: item.sellPrice,
        previous_source: this.$route.name,
      }
    },
    trackReport(obj) {
      this.Sensors.track('recorded_class_card_click', obj)
    },
    cardClickHandler(cardData) {
      this.$router.push(`/courses/recorded-detail/${cardData.id}`)
      const reportData = this.getVisuallyTrackData(cardData)
      this.trackReport(reportData)
    },
  },
}
