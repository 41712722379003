export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 栏目标题
    theme: {
      default: '',
      type: String,
    },
    // 栏目简介
    themeIntro: {
      default: '',
      type: String,
    },
    // 组件url
    iframeUrl: {
      default: '',
      type: String,
    },
  },
  methods: {
    init(){
      window.Trustpilot.Modules.WidgetManagement.initializeOnPageLoad()
    }
  },
  mounted(){
    this.init()
  },
}
