export default {
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isKeepTwoDecimal: this.$t('common.isKeepTwoDecimal'),
      recordedConfig: this.$t('featureComponents.Goods.recordingDetail'),
    };
  },
  computed: {
    currency() {
      return this.$store.state.currency.symbol;
    },
    minorUnit() {
      return this.$store.state.currency.minorUnit;
    },
  },
}
