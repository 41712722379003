export default {
  props: {
    // 组件名称
    componentName: {
      default: 'SuctionBottom',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 展示文案
    copywriting: {
      default: '',
      type: String,
    },
    // 按钮数组
    dataList: {
      default: [],
      type: Array,
    },
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
