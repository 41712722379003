export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 栏目标题
    title: {
      default: '',
      type: String,
    },
    // 栏目描述
    describe: {
      default: '',
      type: String,
    },
    // 内容项列表
    dataList: {
      default: () => [],
      type: Array,
    },
  },
}
