<template>
  <div class="wrapper">
    <template v-if="!buttonName">
      <ModuleTextHeader :title="title" :describe="describe" />
    </template>
    <template v-else>
      <ModuleMoreHeader
        :column-title="title" :more-name="buttonName" :more-url="buttonUrl" :is-big-title="true"
        @more-click="buttonTrackReport({ buttonName })"
      />
      <div class="split-line text-overflow-ellipsis-4">
        {{ describe }}
      </div>
    </template>
    <div class="ad7-content">
      <img v-if="dataList.length === 1" v-lazy-load :data-src="dataList[0].imageUrl" alt="" v-lazy-load>
      <el-carousel v-else indicator-position="outside" height="332px" arrow="never" loop>
        <el-carousel-item v-for="(item, index) in dataList" :key="index">
          <img v-lazy-load :data-src="item.imageUrl" alt="" loading="lazy" @click="cardTrackReport">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（七）
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleMoreHeader from '../Common/ModuleMoreHeader/web.vue'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue'

export default {
  name: 'Ad7',
  components: {
    ModuleMoreHeader,
    ModuleTextHeader,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
}
</script>
<style lang="scss" scoped>
.split-line {
  padding-top: 10px;
  margin-bottom: 30px;
  border-top: 1px solid #e6e6e6;
}

.ad7-content {
  img {
    width: 1240px;
    height: 332px;
  }

  /deep/.el-carousel__indicator.el-carousel__indicator--horizontal {
    .el-carousel__button {
      width: 40px;
      height: 4px;
      background: #fff0ce;
      border-radius: 4px;
      opacity: 1;
    }

    &.is-active {
      .el-carousel__button {
        background: var(--txt-color-link);
      }
    }
  }
}
</style>
