<template>
  <div class="wrapper">
    <ModuleTextHeader :title="theme" :describe="themeIntro" />
    <div v-html="iframeUrl" class="if-center"></div>
  </div>
</template>

<script>
import mixin from './mixin';
import ModuleTextHeader from '../Common/ModuleTextHeader/web.vue'

export default {
  name: 'Trustpilot',
  components: {
    ModuleTextHeader,
  },
  mixins: [mixin],
}
</script>
<style lang="scss">
.if-center {
  display: flex;
  justify-content: center;
}
.trustpilot-widget a{
  color: #fff;
}
</style>
