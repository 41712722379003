<template>
  <div class="mock-exam-card">
    <div class="card-body">
      <div class="card-main">
        <div class="title">
          {{ title }}
        </div>
        <div class="field-group">
          <div class="field-name">
            {{ langContext.difficulty }}:
          </div>
          <div class="field-content">
            <Rate :rate-level="difficulty" :max="5" icon="icon-star" />
          </div>
        </div>
        <div class="field-group">
          <div class="field-name">
            {{ langContext.mockDuration }}:
          </div>
          <div class="field-content color-gray">
            {{ examMockDuration }} mins
          </div>
        </div>
        <div class="field-group vertical">
          <div class="field-name">
            {{ langContext.intro }}:
          </div>
          <div class="field-content color-gray text-overflow-ellipsis-2">
            {{ intro }}
          </div>
        </div>
        <div class="card-attr">
          <div class="attr-item attr-time">
            {{ moment(datetime).format(datetimeFormat) }}
          </div>
          <div class="attr-item attr-people">
            {{ examPersonNums }}
          </div>
        </div>
      </div>
      <div class="card-action">
        <div class="action-wrapper">
          <template v-for="(item, index) in buttons">
            <div v-if="item.type === 'exam'" :key="index" class="button-item">
              <el-button
                class="button uk-button-normal button-start-now"
                @click="handleExamButton(item.id); buttonTrackReport({ buttonName: item.name })"
              >
                {{ item.name }}
              </el-button>
            </div>
            <div v-if="item.type === 'download'" :key="index" class="button-item button-text-item">
              <div
                class="btn-text"
                @click="handleDownloadButton(item.id); buttonTrackReport({ buttonName: item.name })"
              >
                {{ item.name }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 模拟考试卡片组件
 */
import { oneApi } from 'config/api'
import { s3Download } from 'common/utils.js'
import bus from 'common/bus'
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import Rate from 'components/common/Rate'

export default {
  name: 'MockExamCard',
  components: {
    Rate,
  },
  mixins: [mixin, trackReportMixin],
  methods: {
    /**
     * 处理诊断按钮逻辑
     * @param {Number} id 诊断id
     */
    handleExamButton(id) {
      if (!this.isLogin) {
        bus.$emit('login');
        return
      }
      this.examJump(id)
    },

    /**
     * 处理资源下载跳转
     * @param {Number} id 资源id
     */
    async handleDownloadButton(id) {
      console.log('PC邮箱2')
      if (!this.isLogin) {
        bus.$emit('login', {triggerSource: 'down'});
        return
      }
      const info = await this.$axios.post(oneApi.queryUserBasicInfo, { fillInAttention: true }, { rewritePostBody: true })
      if (!info.data.email) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'email',
        })
        return
      }
      if (!info.data.phone) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'phone',
        })
        return
      }
      const res = await this.$axios.post(oneApi.getUrl, id, { rewritePostBody: true });
      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg);
        return
      }
      const resData = res.data || {}
      s3Download(resData.signedUrl)
    },
  },
}
</script>

<style lang="scss" scoped>
.mock-exam-card {
  padding: 40px;
  background: #fff;
  border-radius: 12px;

  .card-body {
    display: flex;
    justify-content: space-between;
  }

  .card-attr {
    display: flex;
    margin-top: 50px;

    .attr-item {
      margin-right: 40px;
      font-size: 16px;
      color: var(--txt-color-lv1);
      padding-left: 25px;

      &.attr-time {
        background: url('./assets/icon-clock.png') left center no-repeat;
        background-size: 16px 16px;
      }

      &.attr-people {
        background: url('./assets/icon-people.png') left center no-repeat;
        background-size: 16px 16px;
      }
    }
  }

  .card-main {
    width: 740px;
  }

  .card-action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
  }

  .title {
    margin-bottom: 10px;
    font-size: 26px;
    color: var(--txt-color-lv1);
    font-weight: 500;
    word-break: break-word;
  }

  .field-group {
    margin-bottom: 10px;
    display: flex;

    .field-name {
      margin-right: 15px;
      color: var(--txt-color-lv1);
    }

    .field-content {
      font-size: 16px;
      line-height: 20px;
      word-break: break-word;
    }

    &.vertical {
      display: block;

      .field-name {
        margin-bottom: 10px;
      }
    }
  }

  .color-gray {
    color: rgba(23, 43, 77, 0.6);
  }

  .action-wrapper {
    .button-item {
      margin: 20px 0;
      text-align: center;
    }

    .button-text-item {
      cursor: pointer;
    }

    .button-start-now {
      width: 160px;
      height: 48px;
    }
  }
}
</style>
