import { oneApi } from 'config/api'

export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 组件ID
    moduleId: {
      default: 0,
      type: Number,
    },
    // 分类标题
    title: {
      default: '',
      type: String,
    },
    // 打开方式
    // page、module
    targetType: {
      default: '',
      type: String,
    },
    // 是否启用频道组
    enableGroup: {
      default: false,
      type: Boolean,
    },
    // 一级分类列表
    category: {
      default: () => [],
      type: Array,
    },
    // 分类组列表
    categoryGroup: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    const { locale } = this.$store.state
    return {
      langContext: this.$t('dynamicComponents.Category'),
      hover: null,
      selectedGroupIndex: 0,
      selectedIndex: 0,
      loadingModules: true,
      moduleList: [],
      locale,
    }
  },
  methods: {
    /**
     * 获取模块列表数据
     * @param {Array} list 模块id列表
     */
    async queryModuleList(list) {
      if (!list || !list.length) return
      const moduleIdList = list.map((item) => item.id)
      this.loadingModules = true
      const params = {
        moduleIdList,
      }
      const res = await this.$axios.post(oneApi.queryPageModule, params, { rewritePostBody: true })
      this.loadingModules = false
      if (res.code !== 0) {
        return
      }
      try {
        const moduleList = []
        res.data.moduleList.forEach((item) => {
          if (item.content) {
            const modueleContent = JSON.parse(item.content)
            modueleContent.moduleId = item.id
            const modueleCompData = modueleContent.componentData || {}
            if (item.type === 46 && Array.isArray(modueleCompData.dataList) && !modueleCompData.dataList.length) return
            moduleList.push(modueleContent)
          }
        })
        this.moduleList = moduleList
        console.log('moduleList', moduleList)
      } catch (error) {
        console.log(error)
      }
    },

    addClass(val) {
      this.hover = val
    },

    removeClass() {
      this.hover = null
    },

    getVisuallyTrackData(item) {
      return {
        cms_component_type: item.componentType,
        cms_component_name: item.componentName,
        cms_component_title: item.componentTitle,
        cms_component_id: item.moduleId,
        previous_source: this.$route.name,
      }
    },

    getCategoryClickTrackData(categoryName) {
      return {
        cms_component_type: this.componentType,
        cms_component_name: this.componentName,
        cms_component_title: this.componentTitle,
        cms_component_id: this.moduleId,
        card_name: '0',
        button_name: categoryName,
        previous_source: this.$route.name,
      }
    },
  },
}
