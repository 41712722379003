<template>
  <div class="wrapper" v-lazy-background lazy-background="./assets/imgs/comments-web.png">
    <ModuleTextHeader :title="title" :describe="describe" />
    <!-- <div class="header">
      <div class="title text-overflow-ellipsis-2">
        {{ title }}
      </div>
      <div v-if="describe" class="describe text-overflow-ellipsis-4">
        {{ describe }}
      </div>
    </div> -->
    <div class="content">
      <div ref="mySwiper" v-swiper:mySwiper="swiperOption" class="swiper-container">
        <div class="swiper-wrapper" style="transition-timing-function: linear;">
          <div
            v-for="(item, index) in dataList"
            :key="index"
            class="swiper-slide"
          >
            <TextAroundImg
              :image-url="item.imageUrl"
              :title="item.title"
              :sub-title="item.subtitle"
              :describe="item.describe"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 评价组件
 */
import mixin from './mixin'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import TextAroundImg from './TextAroundImg/web.vue'
import ModuleTextHeader from '../Common/ModuleTextHeader/web.vue'

export default {
  name: 'UserComments',
  /* eslint-disable-next-line */
  components: { ModuleTextHeader, TextAroundImg, Swiper, SwiperSlide,},
  directives: {
    swiper: directive,
  },
  mixins: [mixin],
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        lazy:{
          loadPrevNext:true, // 预加载前一个后一个
        },
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        preloadImages: false,
        loopAdditionalSlides: 2,
        loopedSlides: 5,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        speed: 14000,
        autoplayDisableOnInteraction: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
      },
    }
  },
  // methods: {
  //   stopSwiper() {
  //     console.log(this.$refs.mySwiper.swiper.autoplay.stop(), 'this.$refs.mySwiper');
  //     this.$refs.mySwiper.swiper.autoplay.stop()
  //   },
  //   startSwiper() {
  //     this.$refs.mySwiper.swiper.autoplay.start()
  //   },
  // },
}
</script>
<style lang="scss" scoped>
.wrapper {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

// .header {
//   text-align: center;
//   color: var(--txt-color-lv1);
//   margin: 0 0 80px;
//   cursor: default;

//   .title {
//     margin-bottom: 20px;
//     font-size: 24px;
//     font-weight: bold;
//     line-height: 30px;
//   }

//   .describe {
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 20px;
//   }
// }

.content {
  padding: 10px 40px 60px;
}

.swiper-container {
  .swiper-slide {
    width: 380px;
    height: 440px;
    margin-right: 30px;
  }
}
</style>
