/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
import bus from 'common/bus'
import { oneApi } from 'config/api';
import evaluation from 'common/evaluation';

export default {
  props: {
    // 组件名称
    componentName: {
      default: 'CourseListComponent',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    theme: {
      default: '',
      type: String,
    },
    themeIntro: {
      default: '',
      type: String,
    },
    classListNum: {
      default: 2,
      type: Number,
    },
    classInfo: {
      default: {
        year: '',
        semester: '',
        semesterLabel: '',
        classList: [],
        subjectId: '',
      },
      type: Object,
    },
    classInfo2: {
      default: {
        year: '',
        semester: '',
        semesterLabel: '',
        classList: [],
        subjectId: '',
      },
      type: Object,
    },
  },
  data() {
    const config = this.getConfig()
    const {
      studentId, fromPage,
    } = this.$route.query;
    return {
      gradeList: [],
      learningCenter: 'Online',
      changeClass: 1,
      listNow: [],
      popBoxShow: false,
      loading: false,
      syllabusList: [],
      backDate: [],
      backDate2: [],
      dateFormat: '',
      clientOptions: '',

      submitName: config.submitName,
      agreementNotice: config.agreementNotice,
      successNotice: config.successNotice,
      errorNotice: config.errorNotice,
      phoneError: config.phoneError,
      requiredError: config.requiredError,
      emailError: config.emailError,
      otpSend: config.otpSend,
      otp: config.otp,
      otpResend: config.otpResend,
      otpPlaceholder: config.otpPlaceholder,
      rules: config.rules,
      otpError: config.otpError,
      legalCheck: config.legalCheck,
      successOtp: config.successOtp,
      signUpCheckInfoDialog: config.signUpCheckInfoDialog,
      redeemCodeEntrance: config.redeemCodeEntrance,
      loadingTxt: config.loadingTxt,
      studentsList: [],
      studentInfo: [],
      skuId: '',
      courseId: '',
      detailData: {},
      isNeedRefresh: false,
      signUpStatus: 0,
      phoneCodeList: this.$store.state.phoneCodeList,
      phoneAreaCode: {},
      confirmDialogConfig: {
        show: false,
        title: '',
        content: '',
        mainBtnText: '',
        mainBtnClick: () => { },
        subBtnText: '',
        subBtnClick: () => { },
      },
      // 诊断弹窗信息
      evaluationDialogInfo: {
        title: '',
        message: '',
        confirmButtonText: '',
        showCancel: true,
        res: {},
      },
      showEvaluationDialog: false,
      studentId,
      fromPage,
      // 购买流程中
      isBuying: false,
      platformTypeNum: null,
      lv1Qury: {
        gradeId: [],
        lv3Date: [],
      },
    }
  },
  computed: {
    isWebClient() {
      return process.env.clientType === 'web'
    },
    userInfo() {
      return this.$store.state.ucenter.user;
    },
    timezone() {
      return this.$store.state.timezone
    },
  },
  methods: {
    async functionCatch(fn) {
      try {
        const res = await fn
        return res
      } catch (error) {
        this.loading = false
        return {
          code: 999,
          msg: error,
        }
      }
    },
    updateQueryStringParameter(queryString, key, value) {
      // 如果 queryString 以 '?' 开头，去掉它
      if (queryString.startsWith('?')) {
        queryString = queryString.substring(1);
      }
      const params = new URLSearchParams(queryString);
      // 删除所有现有的 key 实例
      while (params.has(key)) {
        params.delete(key);
      }
      // 添加新的 key 实例
      params.append(key, value);
      return `?${params.toString()}`;
    },
    async lv2Change(item) {
      const oldQu = window.location.search
      if (item.show) {
        this.lv1Qury.gradeId = this.lv1Qury.gradeId.filter((e) => e != item.grade)
        console.log('lv2删除', this.lv1Qury.gradeId)
      } else {
        this.lv1Qury.gradeId.push(item.grade)
        this.lv1Qury.gradeId = this.unique(this.lv1Qury.gradeId)
        console.log('lv2添加', this.lv1Qury.gradeId)
      }
      let newUrl = this.updateQueryStringParameter(oldQu, 'learningCenter', this.platformTypeNum || 'Online')
      newUrl = this.updateQueryStringParameter(newUrl, 'lv1Qury', encodeURIComponent(JSON.stringify(this.lv1Qury)))
      newUrl = this.$route.path + this.updateQueryStringParameter(newUrl, 'changeClass', this.changeClass)

      window.history.replaceState('', '', newUrl)
      item.lv3Date = []
      item.show = !item.show
      if (!item.show) return
      const data = await this.getInfoDate(item)
      item.lv3Date = data
      console.log('写进数据')
      if (this.changeClass == 1) {
        this.backDate = JSON.parse(JSON.stringify(this.listNow))
      } else {
        this.backDate2 = JSON.parse(JSON.stringify(this.listNow))
      }
    },
    changeShow(item) {
      if (item.show) {
        this.lv1Qury.lv3Date = this.lv1Qury.lv3Date.filter((e) => e != item.levelDegreeId)
        console.log('lv3删除', this.lv1Qury.lv3Date)
      } else {
        this.lv1Qury.lv3Date.push(item.levelDegreeId)
        this.lv1Qury.lv3Date = this.unique(this.lv1Qury.lv3Date)
        console.log('lv3添加', this.lv1Qury.lv3Date)
      }
      const oldQu = window.location.search

      let newUrl = this.updateQueryStringParameter(oldQu, 'learningCenter', this.platformTypeNum || 'Online')
      newUrl = this.updateQueryStringParameter(newUrl, 'lv1Qury', encodeURIComponent(JSON.stringify(this.lv1Qury)))
      newUrl = this.$route.path + this.updateQueryStringParameter(newUrl, 'changeClass', this.changeClass)

      window.history.replaceState('', '', newUrl)
      item.show = !item.show
      if (this.changeClass == 1) {
        this.backDate = JSON.parse(JSON.stringify(this.listNow))
      } else {
        this.backDate2 = JSON.parse(JSON.stringify(this.listNow))
      }
    },
    changeClassF(num) {
      const oldQu = window.location.search

      let newUrl = this.updateQueryStringParameter(oldQu, 'learningCenter', this.platformTypeNum || 'Online')
      newUrl = this.updateQueryStringParameter(newUrl, 'lv1Qury', encodeURIComponent(JSON.stringify(this.lv1Qury)))
      newUrl = this.$route.path + this.updateQueryStringParameter(newUrl, 'changeClass', num)

      window.history.replaceState('', '', newUrl)
      if (this.changeClass !== num) {
        this.changeClass = num
        if (this.changeClass == 1) {
          if (this.backDate.length > 0) {
            return this.listNow = this.backDate
          }
          this.resizeList(1)
        } else {
          if (this.backDate2.length > 2) {
            return this.listNow = this.backDate2
          }
          this.resizeList(2)
        }
      }
    },
    handleGradeChange() {
      console.log('slc改变了', this.learningCenter);
      this.platformTypeNum = null
      this.gradeList.forEach((e) => {
        if (e.venueName == this.learningCenter) {
          this.platformTypeNum = e.id
        }
      })
      if (this.platformTypeNum) {
        this.lv1Qury = {
          gradeId: [],
          lv3Date: [],
        }
        const oldQu = window.location.search

        let newUrl = this.updateQueryStringParameter(oldQu, 'learningCenter', this.platformTypeNum || 'Online')
        newUrl = this.updateQueryStringParameter(newUrl, 'lv1Qury', encodeURIComponent(JSON.stringify(this.lv1Qury)))
        newUrl = this.$route.path + this.updateQueryStringParameter(newUrl, 'changeClass', this.changeClass)

        window.history.replaceState('', '', newUrl)
      }

      this.backDate = []
      this.backDate2 = []
      if (this.changeClass == 1) {
        this.resizeList(1)
      } else {
        this.resizeList(2)
      }
    },
    showPop(item) {
      this.popBoxShow = true;
      this.syllabusList = item
    },
    closePop() {
      this.popBoxShow = false;
    },
    // 获取数据
    async getInfoDate(itemDate) {
      // const params = {
      //   year: '2024',
      //   termId: 13,
      //   gradeId: 47,
      //   subjectId: 8,
      //   platformType: 4,
      // }
      itemDate.loading = true
      let platformTypeNum = null
      this.gradeList.forEach((e) => {
        if (e.venueName == this.learningCenter) {
          platformTypeNum = e.id
        }
      })
      let params = {}
      if (this.changeClass == 1) {
        params = {
          year: this.classInfo.year,
          termId: Number(this.classInfo.semester),
          gradeId: Number(itemDate.grade),
          subjectId: Number(this.classInfo.subjectId) || 8,
        }
      } else {
        params = {
          year: this.classInfo2.year,
          termId: Number(this.classInfo2.semester),
          gradeId: Number(itemDate.grade),
          subjectId: Number(this.classInfo2.subjectId) || 8,
        }
      }
      if (this.learningCenter == 'Online') {
        params.platformType = 4
      } else {
        params.platformType = 3
        params.venueId = platformTypeNum
      }
      this.loading = true
      const webClient = process.env.clientType === 'web'
      const res = await this.functionCatch(this.$axios.post(oneApi.filterInModule, { filter: params }, { rewritePostBody: true }));
      if (Number(res.code) !== 0) {
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: res.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
        this.loading = false
        itemDate.loading = false
        return [];
      }
      itemDate.loading = false
      this.loading = false
      const newList = []
      const list = res.data.list || []
      list.forEach((e) => {
        newList.push({ ...e, show: false })
      })
      return newList
    },
    resizeList(num) {
      const list = []
      if (num == 1) {
        this.classInfo.classList.forEach((item) => {
          list.push({
            ...item, show: false, loading: false, lv3Date: [],
          })
        })
      } else {
        this.classInfo2.classList.forEach((item) => {
          list.push({
            ...item, show: false, loading: false, lv3Date: [],
          })
        })
      }
      this.listNow = list
    },
    toTeacher(id) {
      const url = `${window.location.origin}/teachers/detail/${id}`
      window.open(url)
    },
    toLink(url) {
      // console.log('url', url);
      window.open(url)
      // window.open('https://www.baidu.com')
    },
    async handleEnroll(classInfo) {
      console.log('courseInfo', classInfo);
      this.detailData = classInfo
      this.skuId = classInfo.skuId
      this.courseId = classInfo.courseId
      await this.registrationCheckUser();
    },
    unique(arr) {
      return Array.from(new Set(arr))
    },
    async registrationCheckUser() {
      // 校验用户资格
      // 判断是否登录状态
      const { isLogin } = this.$store.state.ucenter;
      const webClient = process.env.clientType === 'web'
      if (!isLogin) {
        const currentFullPath = this.$route.fullPath
        const successUrl = encodeURI(currentFullPath)
        if (webClient) {
          bus.$emit('login', { triggerSrc: 'REGISTRATION_COURSE', triggerSource: 'courses-detail-registration' });
        } else {
          this.$router.replace(`/login?cancelUrl=${currentFullPath}&triggerSrc=REGISTRATION_COURSE&successUrl=${successUrl}&triggerSource=courses-list-component`);
        }
        this.isBuying = false
        return
      }

      // 子账号优化：
      // 家庭中无子账号+首次下单 => 去掉选择子账号流程
      // 判断是否有子账号
      const resPromise = this.$axios.post(oneApi.getFamilyStudentsList, {}, { rewritePostBody: true });
      const infoPromise = this.$axios.post(oneApi.queryUserBasicInfo, { fillInAttention: true }, { rewritePostBody: true, uid: this.studentId })
      const res = await resPromise
      const info = await infoPromise

      if (Number(res.code) !== 0) {
        this.isLoading = false;
        if (webClient) {
          this.$ElMessage.error(res.msg)
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
        return
      }
      const resData = res.data || {};
      this.studentsList = [resData.currentAccount, ...(resData.associatedAccount || [])];
      const hasAccount = resData.associatedAccount && resData.associatedAccount.length > 0
      // 1、若家庭组中无子账号
      // 若为第一次下单，则去掉选择子账号流程
      // tagId === 1，首次下单
      // 若账号下已经有订单（下过单即可，取消的也算），则正常弹窗引导用户选择子账号
      // 2、若家庭组中有子账号
      // 同当前线上，弹窗引导用户选择子账号
      console.log('有子账号 - ', hasAccount)
      console.log('是否是首次下单', info)
      console.log(this.fromPage)
      this.studentInfo = {
        uid: info.data.id,
        nickName: info.data.nickName || '',
        firstName: info.data.firstName || '',
        lastName: info.data.lastName || '',
        email: info.data.email || '',
        phone: info.data.phone || '',
      }
      if (this.studentId) {
        this.registrationCourse()
      } else if (!hasAccount && [1, '1'].includes(info.data.tagId)) {
        console.log('绕过学生弹窗，直接走报名校验')
        this.registrationCourse()
      } else {
        console.log('选择学生弹窗')
        // 选择学员
        this.openSwitchStudentDialog()
      }
    },
    /**
   * 报名校验
   */
    async registrationCourse() {
      console.log('开始校验')
      this.isBuying = true

      const { isLogin } = this.$store.state.ucenter;
      const webClient = process.env.clientType === 'web'
      if (!isLogin) {
        const currentFullPath = this.$route.fullPath
        const successUrl = encodeURI(currentFullPath)
        if (webClient) {
          bus.$emit('login', { triggerSrc: 'REGISTRATION_COURSE', triggerSource: 'courses-detail-registration' });
        } else {
          this.$router.replace(`/login?cancelUrl=${currentFullPath}&triggerSrc=REGISTRATION_COURSE&successUrl=${successUrl}&triggerSource=courses-list-component`);
        }
        this.isBuying = false
        return
      }
      // 需要校验用户诊断成绩
      const stuEvaluationStatus = await this.checkStuEvaluation()
      // 诊断校验未通过，无法报名
      if (!stuEvaluationStatus) { this.isBuying = false; return }
      // 跳走或直接支付
      this.$router.push(`/order/create/${this.skuId}?fromPage=courses-list-component&lc_name=${this.learningCenter}`)
    },

    // 根据用户诊断成绩校验用户报名资格
    async checkStuEvaluation() {
      const data = { skuId: this.skuId }
      const res = await this.$axios.post(oneApi.querySignUpCheckv2, data, { rewritePostBody: true, uid: this.studentId })

      const webClient = process.env.clientType === 'web'
      if (res.code !== 0) {
        if (webClient) {
          this.$ElMessage.error(res.msg)
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
        return false
      }

      const resData = res.data || {}
      if (resData.continue) return true

      // 判断是新诊断逻辑还是旧诊断逻辑
      if (resData.helper && resData.helper.old) {
        this.setEvaluationDialogOld(resData)
      } else {
        const helper = resData.helper || {}
        this.setSignUpCheckInfoDialog(helper)
      }

      // continue 为false，需要根据helper做进一步处理

      return false
    },
    openSwitchStudentDialog() {
      if (this.isWebClient) {
        this.$refs.coursesDetailSwitchStudentDialog.openDialog();
      } else {
        this.$refs.registrationSwitchStudentPopup.openPicker();
      }
    },
    // 设置确认弹窗
    setConfirmDialog(...args) {
      this.confirmDialogConfig = Object.assign(this.confirmDialogConfig, args[0])
    },
    // 切换学员成功处理
    switchSuccessHandler(isSwitch, studentInfo) {
      this.isNeedRefresh = isSwitch
      this.studentInfo = studentInfo
      this.registrationCourse()
    },
    // 报名资格校验结果
    setSignUpCheckInfoDialog(helper) {
      const status = helper.status || helper.status === 0 ? helper.status : ''
      this.signUpStatus = status
      switch (status) {
        case 1: {
          // 需要入学测，剩余作答机会>0
          const leftChance = helper.extra && helper.extra.leftChance ? helper.extra.leftChance : 0
          const examId = helper.extra && helper.extra.examId ? helper.extra.examId : ''
          const queryObj = {
            courseId: this.courseId || '',
            skuId: this.skuId,
          }
          const isTmc = this.$store.state.locale === 'tmc'
          this.setConfirmDialog({
            show: true,
            title: this.$t('coursesDetail.signUpCheckInfoDialog.case1.title'),
            content: this.$t('coursesDetail.signUpCheckInfoDialog.case1.content', [leftChance, leftChance > 1 ? 's' : '', isTmc ? `您的孩子需要通过入学诊断，然后才能报名参加这个课程，只有${leftChance}次机会` : '']),
            mainBtnText: this.$t('coursesDetail.signUpCheckInfoDialog.case1.mainBtnText'),
            mainBtnClick: () => {
              // 美分&非数学学科
              const subjuetFullName = this.detailData.subjectFullName.toLocaleLowerCase() || ''
              if (this.$store.state.locale === 'us' && !subjuetFullName.includes('math')) {
                this.$router.push('/1-to-1-assessment');
                return
              }
              const webClient = process.env.clientType === 'web'
              if (!examId) {
                if (webClient) {
                  this.$ElMessage.error('no examId')
                } else {
                  this.$Notify({ type: 'warning', message: 'no examId' })
                }
                return
              }
              evaluation.toEvaluation(this.$store, `/evaluation/instruction/${examId}`, queryObj, 'class_register_pop_up')
              this.startTestClick(examId)
            },
            subBtnText: this.$t('coursesDetail.signUpCheckInfoDialog.case604.subBtnText'),
            subBtnClick: () => {
              this.confirmDialogConfig.show = false
            },
          })
          break;
        }
        case 2: {
          // 无法报名-诊断校验未通过
          this.setConfirmDialog({
            show: true,
            title: this.$t('coursesDetail.signUpCheckInfoDialog.case2.unableSignUp'),
            content: helper.statusDesc || this.$t('coursesDetail.signUpCheckInfoDialog.case2.msg'),
            mainBtnText: this.$t('coursesDetail.signUpCheckInfoDialog.case2.ok'),
            mainBtnClick: () => {
              // 美分&非数学学科
              const subjuetFullName = this.detailData.subjectFullName.toLocaleLowerCase() || ''
              if (this.$store.state.locale === 'us' && !subjuetFullName.includes('math')) {
                this.$router.push('/1-to-1-assessment');
                return
              }
              this.confirmDialogConfig.show = false
            },
          })
          break
        }
        case 3: {
          // 无法报名-不符合报班资格(不符合班课策略要求)
          const msg = helper.statusDesc || ''
          this.setConfirmDialog({
            show: true,
            title: this.$t('coursesDetail.signUpCheckInfoDialog.case3.title'),
            content: msg,
            mainBtnText: this.$t('coursesDetail.signUpCheckInfoDialog.case3.mainBtnText'),
            mainBtnClick: () => {
              this.confirmDialogConfig.show = false
            },
            subBtnText: '',
            subBtnClick: () => { },
          })
          break;
        }
        case 4: {
          this.checkEmailOrPhone('email', true, this.newEmailOrPhone)
          break;
        }
        case 5: {
          this.checkEmailOrPhone('phone', true, this.newEmailOrPhone)
          break;
        }
        case 6: {
          // 需要完善个人信息
          bus.$emit('complete-stu-info', { triggerSrc: 'REGISTRATION_COURSE', studentInfo: this.studentInfo || this.studentsList[0] });
          this.isBuying = false;
          break;
        }
        case 1014: {
          // 存在自动扣款中的分期
          this.setConfirmDialog({
            show: true,
            title: this.$t('coursesDetail.signUpCheckInfoDialog.case1014.title'),
            content: this.$t('coursesDetail.signUpCheckInfoDialog.case1014.content'),
            mainBtnText: this.$t('coursesDetail.signUpCheckInfoDialog.case1014.mainBtnText'),
            mainBtnClick: () => {
              this.confirmDialogConfig.show = false
              this.init()
            },
            subBtnText: '',
            subBtnClick: () => { },
          })
          break;
        }
        case 604: {
          // 限时购-简版提示弹窗
          this.setConfirmDialog({
            show: true,
            title: this.$t('coursesDetail.signUpCheckInfoDialog.case604.title'),
            content: this.$t('coursesDetail.signUpCheckInfoDialog.case604.content'),
            mainBtnText: this.$t('coursesDetail.signUpCheckInfoDialog.case604.mainBtnText'),
            mainBtnClick: () => {
              this.confirmDialogConfig.show = false
              this.$router.push('/courses/list')
            },
            subBtnText: this.$t('coursesDetail.signUpCheckInfoDialog.case604.subBtnText'),
            subBtnClick: () => {
              this.confirmDialogConfig.show = false
            },
          })
          break;
        }
        default: {
          const webClient = process.env.clientType === 'web'
          const msg = helper.statusDesc || 'system error'
          this.$ElMessage.error(msg)
          if (webClient) {
            this.$ElMessage.error(msg)
          } else {
            this.$Notify({ type: 'warning', message: msg })
          }
          break;
        }
      }
    },
    // 旧诊断处理逻辑
    setEvaluationDialogOld(res) {
      //  需参与诊断测试
      if (Number(res.helper.status) === 1) {
        this.evaluationDialogInfo = {
          title: 'Evaluation Required',
          message: "Think Academy will recommend the best fit class based on the student's evaluation test",
          confirmButtonText: 'Take Evaluation Now',
          showCancel: true,
          res,
        }
        this.showEvaluationDialog = true;
        return
      }
      // 有成绩但是不符合报班条件
      if (Number(res.helper.status) === 2) {
        this.evaluationDialogInfo = {
          title: "Your evaluation score doesn't satisfy this class level",
          message: 'Based on your evaluation, we recommend you for 1 class level',
          confirmButtonText: 'OK',
          showCancel: false,
          res,
        }
        this.showEvaluationDialog = true;
        return
      }
      // 需要不符合班课策略要求 - 已报名
      if (Number(res.helper.status) === 3) {
        this.evaluationDialogInfo = {
          title: 'Reminder',
          message: res.helper.statusDesc,
          confirmButtonText: 'Confirm',
          showCancel: false,
          res,
        }
        this.showEvaluationDialog = true;
      }
      //  账号缺少联系邮箱
      if (Number(res.helper.status) === 4) {
        this.checkEmailOrPhone('email', true, this.newEmailOrPhone)
        return
      }
      //  账号缺少联系手机号
      if (Number(res.helper.status) === 5) {
        this.checkEmailOrPhone('phone', true, this.newEmailOrPhone)
      }
      //  需要完善个人信息
      if (Number(res.helper.status) === 6) {
        bus.$emit('complete-stu-info', { triggerSrc: 'REGISTRATION_COURSE', studentInfo: this.studentInfo || this.studentsList[0] });
        this.isBuying = false;
      }
    },
    checkEmailOrPhone(type, execute, guide) {
      // 绑定手机号/邮箱优化
      this.$store.dispatch('bindDialog/setDialogData', {
        visible: true,
        type,
        execute: execute || false,
        guide: guide || null,
      })
    },
    async newEmailOrPhone(obj) {
      let data = {}
      let res;
      // let data2 = null

      // if (obj.phone) {
      //   data2 = {
      //     type: 0,
      //     contactInfo: obj.phone,
      //     countryCallingCode: obj.phoneAreaCode.value,
      //   }
      // } else {
      //   data2 = {
      //     type: 2,
      //     contactInfo: obj,
      //   }
      // }
      console.log('校验手机号12');
      // const res2 = await this.$axios.post(oneApi.contactVerify, data2, { rewritePostBody: true })
      // const webClient = process.env.clientType === 'web'
      // if (Number(res2.code) !== 0) {
      //   if (webClient) {
      //     this.$ElMessage.error(res2.msg)
      //   } else {
      //     this.$Notify({ type: 'warning', message: res2.msg })
      //   }
      //   return;
      // }

      if (obj.phone) {
        data = {
          attentionPhone: obj.phone,
          attentionCountryCode: obj.phoneAreaCode.value,
        }
        res = await this.$axios.post(oneApi.bindEmail2, data, { rewritePostBody: true, uid: this.studentId })
      } else {
        data = {
          attentionEmail: obj,
        }
        res = await this.$axios.post(oneApi.bindEmail2, data, { rewritePostBody: true, uid: this.studentId })
      }

      // 触发后续购课流程
      if (Number(res.code) !== 0) {
        if (webClient) {
          this.$ElMessage.error(res.msg)
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
        return;
      }
      this.$store.dispatch('ucenter/queryUserBasicInfo', { $axios: this.$axios, userData: { fillInAttention: false } });
      this.$store.dispatch('bindDialog/close')
      this.registrationCourse()
    },

    /**
   * 获取文案配置
   */
    getConfig() {
      const { locale } = this.$store.state
      let requiredError = 'The input is required.'
      let emailError = 'The Email address seems invalid.'
      let phoneError = 'The Phone Number seems invalid.'
      let submitName = 'Submit'
      let agreementNotice = 'Sign up for our mailing list to receive the latest news, promotions and updates!'
      let successNotice = 'Thank you for your interest in Think Academy!'
      let successOtp = 'OTP has been sent'
      let errorNotice = 'Submission failed. Please try again later.'
      let otp = 'OTP'
      let otpPlaceholder = 'Enter OTP'
      let otpError = 'Incorrect OTP entered.'
      let otpSend = 'Send'
      let loadingTxt = 'The current number of registrants is too high please wait a moment.'
      let otpResend = 'Resend'
      let legalCheck = 'By continuing, you need to be the parents or legal guardian of the students and confirm that you agree to our <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a> and <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and be contacted via text messages and emails.'
      let signUpCheckInfoDialog = {
        case1: {
          title: 'Please take the Evaluation',
          content1: 'Your child must pass the Evaluation before signing up to this course. ',
          content2: 'attempt remaining',
          mainBtnText: 'Start test',
        },
        case2: {
          unableSignUp: 'Unable to sign up',
          ok: 'OK',
          msg: '',
        },
        case3: {
          title: 'Reminder',
          mainBtnText: 'Confirm',
        },
        case1014: {
          title: 'Auto pay in process',
          content: 'Your account will be automatically debited within 24 hours. We will inform you via text message once the payment is competed.',
          mainBtnText: 'I got it',
        },
        case604: {
          title: 'Unable to sign up',
          content: 'Sorry, this course is only available for new users, and each user can only sign up once. Come and browse more courses!',
          mainBtnText: 'View courses',
          subBtnText: 'Cancel',
        },
      }
      let redeemCodeEntrance = ['If you have a Think Academy registration qualification code, please', 'redeem here']
      if (locale === 'hk') {
        loadingTxt = '當前報名人數較多，系統正在處理，請稍等片刻。'
        submitName = '提交'
        agreementNotice = '我想接收最新資訊，包括最新課程資料、學習資源、試題詳解等。'
        successNotice = '提交成功，感謝您對學而思的關註！'
        successOtp = '验证码已发送'
        errorNotice = '提交失敗，請稍後重試。'
        requiredError = '請輸入此必填項'
        emailError = '請輸入有效的郵箱地址'
        phoneError = '請輸入有效的手機號碼'
        otp = '驗證碼'
        otpPlaceholder = '輸入驗證碼'
        otpError = '輸入的驗證碼不正確。'
        otpSend = '發送'
        otpResend = '重發'
        legalCheck = '我已閱讀並同意<a class="link-text" href="/terms-of-use" target="_blank">用戶協議</a>、<a class="link-text" href="/privacy-policy" target="_blank">隱私協議</a>、<a class="link-text" href="/child-privacy" target="_blank">兒童隱私協議</a>'
        signUpCheckInfoDialog = {
          case1: {
            title: '請參加入學評估',
            content1: '您的孩子在報名參加本課程之前必須通過入學評估。剩餘',
            content2: '次嘗試',
            mainBtnText: '確定',
          },
          case2: {
            unableSignUp: '無法報名',
            ok: '確定',
            msg: '您的孩子沒有通過入學評估。我們建議報名參加的課程為：高級級別。',
          },
          case3: {
            title: '提醒',
            mainBtnText: '確定',
          },
          case1014: {
            title: '自動扣款中',
            content: '此班級正在自動扣款中，您的帳戶將在24小時內完成自動扣款，扣款成功後，您將收到簡訊通知。',
            mainBtnText: '知道了',
          },
          case604: {
            title: '無法註冊',
            content: '抱歉，此課程只對新用戶開放，每個用戶只能註冊一次。 快來瀏覽更多課程吧！',
            mainBtnText: '查看課程',
            subBtnText: '取消',
          },
        }
        redeemCodeEntrance = ['如果您有學而思報名資格碼，請在', '這里兌換']
      }
      if (locale === 'tmc') {
        successNotice = 'Thank you for your interest in Mathink Club!'
        legalCheck = 'By continuing, you acknowledge to be the student\'s parent/legal guardian, agree to Mathink Club\'s <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a>, confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receive marketing and promotional materials.'
      }
      if (locale === 'tm') {
        successNotice = 'Thank you for your interest in Think Matrix!'
      }
      if (locale === 'uk') {
        legalCheck = 'By continuing, you acknowledge to be the student\'s parent/legal guardian, agree to Think Academy\'s <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a>, confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receive marketing and promotional materials.'
      }
      if (locale === 'sg' || locale === 'bmsg') {
        legalCheck = 'By continuing, you acknowledge to be the student\'s parent/legal guardian, agree to Think Academy\'s <a id="link-sg-terms-use" class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a>, confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receive marketing and promotional materials.'
      }
      if (locale === 'ca') {
        legalCheck = 'By continuing,you need to be the parents or legal guardian of the students to agree to Think Academy’s <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a> and confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a>.'
      }
      return {
        // 提交按钮名称
        submitName,
        // 协议提示
        agreementNotice,
        // 提交成功提示
        successNotice,
        // 提交失败提示
        errorNotice,
        // 手机号错误提示
        phoneError,
        // 输入错误提示
        requiredError,
        // 邮箱号错误提示
        emailError,
        otp,
        otpPlaceholder,
        otpError,
        otpSend,
        otpResend,
        legalCheck,
        signUpCheckInfoDialog,
        redeemCodeEntrance,
        successOtp,
        loadingTxt,
      }
    },
  },
  async mounted() {
    const webClient = process.env.clientType === 'web'
    const clientOptions = JSON.parse(window.localStorage.getItem('clientOptions'))
    if (!clientOptions) {
      await this.$store.dispatch('ucenter/getClientOptions', { $axios: this.$axios });
    }
    this.clientOptions = JSON.parse(window.localStorage.getItem('clientOptions'))
    const dateFormatMap = {
      UK: 'DD/MM/YYYY',
      FR: 'DD/MM/YYYY',
      JP: 'YYYY/MM/DD',
    }
    this.dateFormat = dateFormatMap[this.clientOptions.dateFormat] || 'MM/DD/YYYY'
    const res = await this.$axios.post(oneApi.venueList, {}, { rewritePostBody: true })
    if (Number(res.code) !== 0) {
      if (webClient) {
        this.$ElMessage({
          type: 'error',
          message: res.msg,
          offset: 80,
        });
      } else {
        this.$Notify({ type: 'warning', message: res.msg })
      }
      return;
    }
    const opList = res.data || []
    opList.unshift({
      id: 'Online',
      venueName: 'Online',
    })
    this.gradeList = opList
    this.resizeList(1)
    let hasGradeId = false

    if (this.$route.query.learningCenter) {
      this.platformTypeNum = this.$route.query.learningCenter
      if (this.$route.query.learningCenter == 'Online') {
        this.learningCenter = 'Online'
      } else {
        this.gradeList.forEach((e) => {
          if (e.id == this.$route.query.learningCenter) {
            this.learningCenter = e.venueName
          }
        })
      }
    }

    if (this.$route.query.changeClass) {
      this.changeClassF(Number(this.$route.query.changeClass))
    }

    this.listNow.forEach(async (e) => {
      if (e.grade == this.userInfo.gradeId && !this.$route.query.lv1Qury) {
        hasGradeId = true
        await this.lv2Change(e)
        e.show = true
        if (e.lv3Date && e.lv3Date.length > 0) {
          this.changeShow(e.lv3Date[0])
        }
      }
    })
    if (!hasGradeId && !this.$route.query.lv1Qury) {
      await this.lv2Change(this.listNow[0])
      this.listNow[0].show = true
      if (this.listNow[0].lv3Date && this.listNow[0].lv3Date.length > 0) {
        this.changeShow(this.listNow[0].lv3Date[0])
      }
    }

    if (this.$route.query.lv1Qury) {
      this.lv1Qury = JSON.parse(decodeURIComponent(this.$route.query.lv1Qury))

      for (let i = 0; i < this.listNow.length; i++) {
        if (this.lv1Qury.gradeId.includes(this.listNow[i].grade) && this.lv1Qury.gradeId.length > 0) {
          await this.lv2Change(this.listNow[i])
          this.listNow[i].show = true
        }
      }

      this.listNow.forEach(async (e) => {
        if (e.lv3Date && e.lv3Date.length > 0) {
          this.lv1Qury.lv3Date.forEach((e2) => {
            e.lv3Date.forEach((e3) => {
              if (e2 == e3.levelDegreeId && this.lv1Qury.lv3Date.length > 0) {
                this.changeShow(e3)
              }
            })
          });
        }
      })
    }
  },
}
