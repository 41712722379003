<template>
  <div class="ad3-bg" v-lazy-background lazy-background="./assets/imgs/comp-ad3-bg.png">
    <div class="wrapper ad3">
      <div class="left">
        <div v-if="dataList.length === 1" class="left-wraper">
          <img v-lazy-load :data-src="dataList[0].imageUrl" alt="" v-lazy-load>
          <div v-if="dataList[0].videoUrl" class="left-play" @click="handleClick" />
        </div>
        <div v-else class="left-wraper">
          <div v-swiper:mySwiper="swiperOption" class="swiper-container">
            <div class="swiper-wrapper">
              <div v-for="(item, index) in dataList" :key="index" class="swiper-slide">
                <img class="swiper-lazy" :data-src="item.imageUrl" alt="">
                <div v-if="item.videoUrl" class="left-play" @click="handleClick" />
              </div>
            </div>
          </div>
          <div class="swiper-pagination" />
        </div>
      </div>
      <div class="right">
        <div class="right-title">
          <div class="text-overflow-ellipsis title">
            {{ title }}
          </div>
          <div class="text-overflow-ellipsis-2 describe">
            {{ describe }}
          </div>
        </div>
        <div v-if="buttonName" class="ad2-bottom">
          <DiffLinkLabel :url="buttonUrl" :target="linkTarget" v-if="skipType != 1">
            <div class="button btn-wraper" @click="buttonTrackReport({ buttonName })">
              <div class="ad2-btn text-overflow-ellipsis">
                {{ buttonName }}
              </div>
            </div>
          </DiffLinkLabel>
          <div class="button btn-wraper" v-else @click="toGn(buttonName)">
            <div class="ad2-btn text-overflow-ellipsis">
              {{ buttonName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <VideoPopup ref="videoPopup" />
  </div>
</template>

<script>
/**
 * 广告样式（三）
 */
import mixin from './mixin'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import VideoPopup from 'components/common/VideoPopup/index';
import DiffLinkLabel from '../Common/DiffLinkLabel/web.vue'
import bus from 'common/bus'

export default {
  name: 'Ad3',
  /* eslint-disable-next-line */
  components: { VideoPopup, DiffLinkLabel, Swiper, SwiperSlide,},
  directives: {
    swiper: directive,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
  data() {
    const vm = this
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        preloadImages: false,
        loopAdditionalSlides: 2,
        loopedSlides: 5,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        lazy:{
          loadPrevNext:true, // 预加载前一个后一个
        },
        speed: 500,
        autoplayDisableOnInteraction: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true, // 点击当前滑块切换到对应的轮播图片
        },
        on: {
          slideChange() {
            vm.changeSwiperIndex(this.realIndex) // realIndex真实索引
          },
        },
      },
    }
  },
  methods: {
    handleClick() {
      this.handleToPlay()
    },
    toGn( buttonName ) {
      this.buttonTrackReport({ buttonName })
      if (!this.isLogin) {
        bus.$emit('login', { triggerSource: 'appointment' });
        return
      }
      bus.$emit('toShowDiagnosis')
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.swiper-pagination {
  position: relative;
  margin-top: 10px;

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    opacity: 0.1;
    margin-right: 10px;
  }

  .swiper-pagination-bullet-active {
    background-color: var(--txt-color-link);
    opacity: 1;
  }
}

.ad3-bg {
  padding: 50px 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.ad3 {
  display: flex;
  justify-content: center;
}

.left {
  .left-wraper {
    width: 491px;
    height: 348px;
    padding: 9px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 19px;
    position: relative;

    img {
      width: 473px;
      height: 330px;
      border-radius: 16px;
    }
  }

  .swiper-container {
    width: 473px;

    .swiper-slide {
      width: 473px;
      height: 330px;
      position: relative;
    }
  }

  .left-play {
    width: 52px;
    height: 52px;
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-image: url('./assets/imgs/play.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 70px;

  .right-title {
    color: var(--txt-color-lv1);
    cursor: default;

    .title {
      width: 460px;
      font-size: 40px;
      font-weight: bold;
      line-height: 60px;
      margin-bottom: 10px;
    }

    .describe {
      width: 460px;
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
    }
  }
}

.ad2-bottom {
  max-width: 364px;
  display: flex;

  .btn-wraper {
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    background: var(--txt-color-link);

    .ad2-btn {
      max-width: 364px;
      min-width: 200px;
      padding: 22px;
    }

    &:hover {
      background: var(--txt-color-link2);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
</style>
