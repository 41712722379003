import { oneApi } from 'config/api';

export default {
  props: {
    // 组件名称
    componentName: {
      default: 'Application',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 组件id
    moduleId: {
      default: '',
      type: String,
    },
    // 分校识别码
    locale: {
      default: '',
      type: String,
    },
    // 标题
    title: {
      default: '',
      type: String,
    },
    // 简介
    intro: {
      default: '',
      type: String,
    },
    // 按钮文案
    submit: {
      default: 'Submit',
      type: String,
    },
    /**
     * 表单字段名称配置
     */
    clue: {
      default: '',
      type: String,
    },
    host: {
      default: '',
      type: String,
    },
    secret: {
      default: '',
      type: String,
    },
    // 字段列表
    dataList: {
      default: () => ([]),
      type: Array,
    },
    registration: {
      default: () => ({}),
      type: Object,
    },
    // 配置来源类型
    type: {
      default: 'MODULE',
      type: String,
    },
  },
  data() {
    const config = this.getConfig()
    const { source_id, source_type, utm_medium, utm_source } = this.$route.query;
    return {
      commonLoginData: false,
      source_id: source_id || '',
      source_type: source_type || '',
      utm_medium: utm_medium || '',
      utm_source: utm_source || '',
      hasFirst: false,
      submitName: config.submitName,
      agreementNotice: config.agreementNotice,
      successNotice: config.successNotice,
      errorNotice: config.errorNotice,
      phoneError: config.phoneError,
      requiredError: config.requiredError,
      emailError: config.emailError,
      otpSend: config.otpSend,
      otp: config.otp,
      otpResend: config.otpResend,
      otpPlaceholder: config.otpPlaceholder,
      rules: config.rules,
      otpError: config.otpError,
      legalCheck: config.legalCheck,
      successOtp: config.successOtp,
      signUpCheckInfoDialog: config.signUpCheckInfoDialog,
      redeemCodeEntrance: config.redeemCodeEntrance,
      loadingTxt: config.loadingTxt,
      loading: false,
      studentsList: [],
      studentInfo: [],
      skuId: '',
      courseId: '',
      signUpStatus: 0,
      phoneCodeList: this.$store.state.phoneCodeList,
      phoneAreaCode: {},
      formData: {
        clue: '',
        host: '',
        secret: '',
        subStatus: true,
        zcStatus: true,
        emailShow: false,
        dataList: [],
      },
      djs: 0,
      djsSet: null,
      detailData: {},
      confirmDialogConfig: {
        show: false,
        title: '',
        content: '',
        mainBtnText: '',
        mainBtnClick: () => { },
        subBtnText: '',
        subBtnClick: () => { },
      },
      // 诊断弹窗信息
      evaluationDialogInfo: {
        title: '',
        message: '',
        confirmButtonText: '',
        showCancel: true,
        res: {},
      },
      showEvaluationDialog: false,
      courseDetail: {},
      // 订阅税
      subTaxPrice: 0,
      orderId: '',
      studentId: '',
      leadsSubmitId: '',
    }
  },
  computed: {
    gradeList() {
      const gradeList = []
      this.dataList.forEach((e) => {
        if (e.key === 'gradeId') {
          e.classList.forEach((i) => {
            if (i.show) {
              gradeList.push(i)
            }
          })
        }
      })
      return gradeList
    },
    resultPath() {
      return `/order/result/${this.orderId}?orderFrom=1&studentId=${this.studentId}&source_id=${this.source_id}&source_type=${this.source_type}&utm_medium=${this.utm_medium}&utm_source=${this.utm_source}`;
    },
    cancelUrl() {
      return `/order/detail/${this.orderId}?orderFrom=1&studentId=${this.studentId}&source_id=${this.source_id}&source_type=${this.source_type}&utm_medium=${this.utm_medium}&utm_source=${this.utm_source}`;
    },
  },
  methods: {
    async init() {
      const gradeList = this.$store.state.courses.gradeList || []
      // 初始化年级数据
      if (Array.isArray(gradeList) && gradeList.length === 0) {
        await this.$store.dispatch('courses/queryGradeList', { $axios: this.$axios })
      }
      // 初始化手机区号对象
      const initPhoneAreaCode = this.$store.state.defaultPhoneCode || this.phoneCodeList[0]
      this.phoneAreaCode = initPhoneAreaCode
      this.handleAreaCodeChange()
    },
    //神策埋点-留资组件曝光上报
    trackReport(name) {
      this.Sensors.track(name, {
        cms_component_id: this.moduleId,
      })
    },
    initFormData() {
      const webClient = process.env.clientType === 'web'
      const dataList = []
      this.hasFirst = false
      this.dataList.forEach((item) => {
        const fieldData = {
          ...item,
          value: '',
          valueName: '', // touch端输入框回显已选择下拉列表数据
          checked: [], // touch端多选
          rules: [{
            required: !!item.required,
            message: this.requiredError,
            trigger: webClient ? 'blur' : 'onBlur',
          }],
        }
        if (item.optionsType === 'select' || item.optionsType === 'selectMore') {
          fieldData.showPopup = false
        }
        if (item.show) {
          if (item.key === 'email' && item.custom === false) {
            this.formData.emailShow = true
          }
          if (item.key === 'fullName') {
            this.hasFirst = true
            dataList.push({
              key: 'givenName',
              name: item.name[0],
              show: item.show,
              optionsType: item.optionsType,
              custom: item.custom,
              index: item.index,
              required: item.required,
              def: item.def,
              value: '',
              valueName: '', // touch端输入框回显已选择下拉列表数据
              checked: [], // touch端多选
              rules: [{
                required: !!item.required,
                message: this.requiredError,
                trigger: webClient ? 'blur' : 'onBlur',
              }],
            })
            dataList.push({
              key: 'familyName',
              name: item.name[1],
              show: item.show,
              optionsType: item.optionsType,
              custom: item.custom,
              index: item.index,
              required: item.required,
              def: item.def,
              value: '',
              valueName: '', // touch端输入框回显已选择下拉列表数据
              checked: [], // touch端多选
              rules: [{
                required: !!item.required,
                message: this.requiredError,
                trigger: webClient ? 'blur' : 'onBlur',
              }],
            })
          } else if (item.key === this.registration.account) {
            dataList.push(fieldData)
            dataList.push({
              key: 'verifyCode',
              optionsType: item.optionsType,
              name: this.otp,
              index: item.index,
              show: item.show,
              custom: item.custom,
              required: item.required,
              value: '',
              type: item.key,
              rules: [{
                required: !!item.required,
                message: this.otpPlaceholder,
                trigger: webClient ? 'blur' : 'onBlur',
              }],
            })
          } else {
            dataList.push(fieldData)
          }
        }
      })
      const orderList = dataList.sort((a, b) => a.index - b.index)
      this.formData.dataList = orderList
    },

    getDataParamsList() {
      let dataParamsList = []
      try {
        this.formData.dataList.forEach((item) => {
          let value = ''
          if (item.optionsType === 'input') {
            if (item.key === 'phone' && item.custom === false) {
              value = item.value
              if (item.value !== '') {
                dataParamsList.push({
                  key: 'areaCode',
                  value: this.phoneAreaCode.value,
                  custom: false,
                })
              }
            } else {
              value = item.value
            }
          }
          if (item.optionsType === 'select') {
            if (item.value !== '') {
              value = item.options[item.value].name
            }
          }
          if (item.optionsType === 'selectMore') {
            // 判断多选无值时
            if (item.value !== '') {
              const tempList = []
              item.value.forEach((option) => {
                tempList.push(item.options[option].name)
              })
              const preValue = tempList.join(',')
              value = JSON.stringify(preValue.split(',')).replace(/"/g, '\"')
            }
          }
          else {
            dataParamsList.push({
              // 自定义字段key传name
              key: item.key || undefined,
              name: item.name,
              value,
              custom: item.custom,
              multi: item.optionsType === 'selectMore',
            })
          }

        })
      } catch (error) {
        console.error(error)
        dataParamsList = []
      }
      return dataParamsList
    },

    // 发送验证码
    async sendCode() {
      const webClient = process.env.clientType === 'web'
      if (this.djs === 0) {
        const arr = this.formData.dataList
        let data = {}
        let vaPass = false
        if (this.registration.account === 'phone') {
          data = {
            scene: 1,
            type: 0, // 0手机号
          }
          arr.forEach((e) => {
            if (e.key === 'phone') {
              data.contactInfo = e.value
              data.countryCallingCode = this.phoneAreaCode.value
            }
          })
          if (webClient) {
            vaPass = this.validateWeb('phone')
            let phoneNum = ''
            this.formData.dataList.forEach((item) => {
              if (item.key === 'phone') {
                phoneNum = item.value
              }
            })
            if (phoneNum.match(this.phoneAreaCode.reg)) {
              vaPass = true
            }
          } else {
            vaPass = await this.validateTouch('phone')
            let phoneNum = ''
            this.formData.dataList.forEach((item) => {
              if (item.key === 'phone') {
                phoneNum = item.value
              }
            })
            if (phoneNum.match(this.phoneAreaCode.reg)) {
              vaPass = true
            }
          }
        } else {
          data = {
            scene: 1,
            type: 2, // 2邮箱
          }
          arr.forEach((e) => {
            if (e.key === 'email') {
              data.contactInfo = e.value
            }
          })
          if (webClient) {
            vaPass = this.validateWeb('email')
            let emailNum = ''
            this.formData.dataList.forEach((item) => {
              if (item.key === 'email') {
                emailNum = item.value
              }
            })
            if (emailNum.match(/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)) {
              vaPass = true
            }
          } else {
            vaPass = await this.validateTouch('email')
            let emailNum = ''
            this.formData.dataList.forEach((item) => {
              if (item.key === 'email') {
                emailNum = item.value
              }
            })
            if (emailNum.match(/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)) {
              vaPass = true
            }
          }
        }
        if (!vaPass) return
        const res = await this.functionCatch(this.$axios.post(oneApi.sendLoginMobileCode, data, { rewritePostBody: true }));
        if (res.code === 0) {
          if (process.env.clientType === 'web') {
            this.$ElMessage({
              type: 'success',
              message: this.successOtp,
            })
          } else {
            this.$Notify({ type: 'success', message: this.successOtp })
          }
          this.djs = 60
          this.djsSet = setInterval(() => {
            if (this.djs === 0) {
              this.otpSend = this.otpResend
              clearInterval(this.djsSet)
              return
            }
            this.djs -= 1
          }, 1000);
        } else if (process.env.clientType === 'web') {
          this.$ElMessage({
            type: 'warning',
            message: res.msg,
          })
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
      }
    },

    validateWeb(type) {
      let name = ''
      let bool = false
      this.formData.dataList.forEach((e) => {
        if (e.key === type) {
          name = `dataList.${e.index}.value`
        }
      })
      this.$refs.formData.validateField(name, (valid) => {
        if (!valid) {
          bool = true;
        } else {
          bool = false
        }
      })
      return bool
    },
    async validateTouch(type) {
      let bool = false
      bool = await this.$refs.SubmitInfo.validate(type)
        .then((valid) => {
          if (!valid) {
            bool = true;
          } else {
            bool = false
          }
          return bool
        })
        .catch(() => {
          return false
        })
      return bool
    },

    async isFreeCourse() {
      const webClient = process.env.clientType === 'web'
      let totalPriceValue = 0
      let needAdd = false

      const data = {
        skuId: this.skuId,
      };

      const res = await this.functionCatch(this.$axios.post(oneApi.queryGoodDetail, data, { rewritePostBody: true }));
      if (Number(res.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: res.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
        return;
      }
      const resData = res.data || {};
      this.courseDetail = Object.assign(this.courseDetail, resData);
      // 商品类型 1 = 课程；2 = 书籍
      let goodType = this.courseDetail.origin * 1 || 1;
      let isFirstGenerate = !resData.existStage
      let requiredAttachedItems = []
      let selectedAttachedItems = []
      if (isFirstGenerate) {
        const attachedItems = this.courseDetail.attachedItems || []
        attachedItems.forEach((attachedItem) => {
          if (attachedItem.required) {
            requiredAttachedItems.push(attachedItem)
          }

          if (!attachedItem.required && attachedItem.selected) {
            selectedAttachedItems.push(attachedItem)
          }
        })
      }
      if (goodType == 2) {
        if (this.$store.state.locale === 'hk' || this.$store.state.locale === 'tm') {
          needAdd = false
        } else {
          needAdd = this.courseDetail.needPostAdd === 0 ? false : true
        }
      } else {
        const selectedAttachedItems2 = requiredAttachedItems.concat(selectedAttachedItems)
        needAdd = selectedAttachedItems2.findIndex((item) => item.needPostAdd) >= 0
      }
      let makePriceIsInclude = resData.makePriceIsInclude
      totalPriceValue = resData.sellPrice

      const country = await this.functionCatch(this.$axios.post(oneApi.queryCountry, {}, { rewritePostBody: true }))
      if (Number(country.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: country.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: country.msg })
        }
        return;
      }
      const countryList = country.data.list;
      let countryId = countryList[0].id;
      const goodsList = [{
        sellPrice: this.courseDetail.singleSellPrice,
        skuId: this.skuId,
        categoryId: this.courseDetail.goodsCategoryId,
      }]
      const data2 = {
        platformType: this.courseDetail.spec.platformType,
        countryId,
        categorys: goodsList,
      }
      const res2 = await this.functionCatch(this.$axios.post(oneApi.queryCategoryTax, data2, { rewritePostBody: true }))
      if (Number(res2.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: res2.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res2.msg })
        }
        return;
      }
      const resData2 = res2.data || {}
      let originPriceList = resData2.list || [];

      if (makePriceIsInclude === 1) {
        totalPriceValue += originPriceList[0].tax
      }

      const data3 = {
        total: totalPriceValue,
        payMethod: 2,
      }
      const res3 = await this.functionCatch(this.$axios.post(oneApi.getHandlingFee, data3, { rewritePostBody: true }))
      if (Number(res3.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: res3.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res3.msg })
        }
        return;
      }

      totalPriceValue += (res3.data.handlingFee || 0)
      console.log('totalPriceValue', totalPriceValue, needAdd)
      if (totalPriceValue === 0 && !needAdd) {
        await this.toPay()
      } else {
        this.$router.push(`/order/create/${this.skuId}?leadsSubmitId=${this.leadsSubmitId}&source_id=${this.source_id}&source_type=${this.source_type}&utm_medium=${this.utm_medium}&utm_source=${this.utm_source}`)
      }
    },

    async toPay() {
      const data = {
        automaticDeduction: 1,
        type: this.courseDetail.origin * 1 || 1,
        skuBOList: [
          {
            skuId: this.skuId,
            ignoreSkuIdList: [],
            num: 1,
          },
        ],
      }
      const res = await this.functionCatch(this.$axios.post(oneApi.createOrder, data, { rewritePostBody: true, leadsSubmitId: this.leadsSubmitId }))
      if (Number(res.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: res.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res.msg })
        }
        return;
      }
      const resData = res.data || {};
      this.orderId = resData.orderId

      const resUid = await this.functionCatch(this.$axios.post(oneApi.getFamilyStudentsList, {}, { rewritePostBody: true }))
      if (Number(resUid.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: resUid.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: resUid.msg })
        }
        return;
      }
      const resDataUid = resUid.data || {}
      const studentId = resDataUid.currentAccount.uid
      this.studentId = studentId
      let api = oneApi.payOrder;
      const domain = window.location.origin;
      // 支付成功的回调页面
      const returnUrl = this.studentId ? domain + this.resultPath : domain + this.resultPath + studentId;
      // 取消支付的回调页面
      const cancelUrl = this.studentId ? domain + this.cancelUrl : domain + this.cancelUrl + studentId;
      const data2 = {
        orderId: resData.orderId,
        returnUrl,
        cancelUrl,
        subscriptionsChannel: 32,
      }
      const res2 = await this.functionCatch(this.$axios.post(api, data2, { rewritePostBody: true }))
      if (Number(res2.code) !== 0) {
        this.loading = false
        if (webClient) {
          this.$ElMessage({
            type: 'error',
            message: res2.msg,
            offset: 80,
          });
        } else {
          this.$Notify({ type: 'warning', message: res2.msg })
        }
        return;
      }
      this.$router.push(this.resultPath);
    },

    async functionCatch(fn) {
      try {
        const res = await fn
        return res
      } catch (error) {
        this.loading = false
        return {
          code: 999,
          msg: error
        }
      }
    },

    /**
     * 获取文案配置
     */
    getConfig() {
      const { locale } = this.$store.state
      let requiredError = 'The input is required.'
      let emailError = 'The Email address seems invalid.'
      let phoneError = 'The Phone Number seems invalid.'
      let submitName = 'Submit'
      let agreementNotice = 'Sign up for our mailing list to receive the latest news, promotions and updates!'
      let successNotice = 'Thank you for your interest in Think Academy!'
      let successOtp = 'OTP has been sent'
      let errorNotice = 'Submission failed. Please try again later.'
      let otp = 'OTP'
      let otpPlaceholder = 'Enter OTP'
      let otpError = 'Incorrect OTP entered.'
      let otpSend = 'Send'
      let loadingTxt = 'The current number of registrants is too high please wait a moment.'
      let otpResend = 'Resend'
      let legalCheck = 'By continuing, you need to be the parents or legal guardian of the students and confirm that you agree to our <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a> and <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and be contacted via text messages and emails.'
      let signUpCheckInfoDialog = {
        case1: {
          title: 'Please take the Evaluation',
          content1: 'Your child must pass the Evaluation before signing up to this course. ',
          content2: 'attempt remaining',
          mainBtnText: 'Start test',
        },
        case2: {
          unableSignUp: 'Unable to sign up',
          ok: 'OK',
          msg: '',
        },
        case3: {
          title: 'Reminder',
          mainBtnText: 'Confirm',
        },
        case1014: {
          title: 'Auto pay in process',
          content: 'Your account will be automatically debited within 24 hours. We will inform you via text message once the payment is competed.',
          mainBtnText: 'I got it',
        },
        case604: {
          title: 'Unable to sign up',
          content: 'Sorry, this course is only available for new users, and each user can only sign up once. Come and browse more courses!',
          mainBtnText: 'View courses',
          subBtnText: 'Cancel',
        },
      }
      let redeemCodeEntrance = ['If you have a Think Academy registration qualification code, please', 'redeem here']
      if (locale === 'hk') {
        loadingTxt = '當前報名人數較多，系統正在處理，請稍等片刻。'
        submitName = '提交'
        agreementNotice = '我想接收最新資訊，包括最新課程資料、學習資源、試題詳解等。'
        successNotice = '提交成功，感謝您對學而思的關註！'
        successOtp = '验证码已发送'
        errorNotice = '提交失敗，請稍後重試。'
        requiredError = '請輸入此必填項'
        emailError = '請輸入有效的郵箱地址'
        phoneError = '請輸入有效的手機號碼'
        otp = '驗證碼'
        otpPlaceholder = '輸入驗證碼'
        otpError = '輸入的驗證碼不正確。'
        otpSend = '發送'
        otpResend = '重發'
        legalCheck = '我已閱讀並同意<a class="link-text" href="/terms-of-use" target="_blank">用戶協議</a>、<a class="link-text" href="/privacy-policy" target="_blank">隱私協議</a>、<a class="link-text" href="/child-privacy" target="_blank">兒童隱私協議</a>'
        signUpCheckInfoDialog = {
          case1: {
            title: '請參加入學評估',
            content1: '您的孩子在報名參加本課程之前必須通過入學評估。剩餘',
            content2: '次嘗試',
            mainBtnText: '確定',
          },
          case2: {
            unableSignUp: '無法報名',
            ok: '確定',
            msg: '您的孩子沒有通過入學評估。我們建議報名參加的課程為：高級級別。',
          },
          case3: {
            title: '提醒',
            mainBtnText: '確定',
          },
          case1014: {
            title: '自動扣款中',
            content: '此班級正在自動扣款中，您的帳戶將在24小時內完成自動扣款，扣款成功後，您將收到簡訊通知。',
            mainBtnText: '知道了',
          },
          case604: {
            title: '無法註冊',
            content: '抱歉，此課程只對新用戶開放，每個用戶只能註冊一次。 快來瀏覽更多課程吧！',
            mainBtnText: '查看課程',
            subBtnText: '取消',
          },
        }
        redeemCodeEntrance = ['如果您有學而思報名資格碼，請在', '這里兌換']
      }
      if (locale === 'tmc') {
        successNotice = 'Thank you for your interest in Mathink Club!'
        legalCheck = 'By continuing, you acknowledge to be the student\'s parent/legal guardian, agree to Mathink Club\'s <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a>, confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receive marketing and promotional materials.'
      }
      if (locale === 'tm') {
        successNotice = 'Thank you for your interest in Think Matrix!'
      }
      if (locale === 'uk') {
        legalCheck = 'By continuing, you acknowledge to be the student\'s parent/legal guardian, agree to Think Academy\'s <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a>, confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receive marketing and promotional materials.'
      }
      if (locale === 'sg' || locale === 'bmsg') {
        legalCheck = 'By continuing, you acknowledge to be the student\'s parent/legal guardian, agree to Think Academy\'s <a id="link-sg-terms-use" class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a>, confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receive marketing and promotional materials.'
      }
      if (locale === 'ca') {
        legalCheck = 'By continuing,you need to be the parents or legal guardian of the students to agree to Think Academy’s <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a> and confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a>.'
      }
      return {
        // 提交按钮名称
        submitName,
        // 协议提示
        agreementNotice,
        // 提交成功提示
        successNotice,
        // 提交失败提示
        errorNotice,
        // 手机号错误提示
        phoneError,
        // 输入错误提示
        requiredError,
        // 邮箱号错误提示
        emailError,
        otp,
        otpPlaceholder,
        otpError,
        otpSend,
        otpResend,
        legalCheck,
        signUpCheckInfoDialog,
        redeemCodeEntrance,
        successOtp,
        loadingTxt,
      }
    },
  },
}
