export default {
  props: {
    // 组件名称
    componentName: {
      default: 'CustomButton',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 自定义组件内容
    // 按钮名称
    buttonName: {
      default: '',
      type: String,
    },
    // 图标图片地址
    iconUrl: {
      default: '',
      type: String,
    },
    // 图标位置（左边or右边）
    iconPosition: {
      default: 'left',
      type: String,
    },
    // 跳转路径
    linkUrl: {
      default: '',
      type: String,
    },
    // 跳转方式
    target: {
      default: '_blank',
      type: String,
    },
    skinType: {
      default: 0,
      type: Number,
    }
  },
  data() {
    return {
      normalDisplay: true,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin
    },
  },
  methods: {
    failRendering() {
      this.normalDisplay = false
    },
  },
}
