<template>
  <div class="page-rich-text">
    <div class="title">
      {{ title }}
    </div>
    <div class="content typo" v-html="content" />
  </div>
</template>

<script>
/**
 * 图文（二）web组件
 */
import mixin from './mixin'

export default {
  name: 'PageRichText',
  mixins: [mixin],
}
</script>

<style lang="scss">
@import "assets/css/typo.scss";
</style>

<style lang="scss" scoped>
.page-rich-text {
  width: 1240px;
  margin: 40px auto 0 auto;

  .title {
    margin-bottom: 40px;
    font-size: 46px;
  }

  .content {
    overflow: hidden;
  }
}
</style>
