export default {
  name: 'Dynamic',
  props: {
    // 组件名称
    componentName: {
      default: 'Dynamic',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
  },
}
