<template>
  <div class="courses-column-footer">
    <div v-if="courseDetail.suitableStudents && showSuitable"
         class="suitable-tudents common-text text-overflow-ellipsis-2"
    >
      {{ courseDetail.suitableStudents }}
    </div>
    <div class="footer-wrapper">
      <div class="lessons-wrapper">
        {{ $t('courses.lessonUnitTxt', [courseDetail.lessonCount, courseDetail.lessonCount > 1 ? 's' : '']) }}
      </div>
      <div class="price-wrapper">
        <template v-if="!courseDetail.isSame">
          <div v-if="locale !== 'hk'" class="from-text">
            {{ langContext.from }}
          </div>
          <div class="price-text">
            {{ currency }}{{ sellPrice| priceUnitConvert(minorUnit, isKeepTwoDecimal) }}
            <span v-if="courseDetail.perShowPrice !== undefined" class="per-lesson-text">{{ langContext.perLesson }}</span>
          </div>
          <div v-if="locale === 'hk'" class="from-text">
            {{ langContext.from }}
          </div>
        </template>
        <template v-else>
          <div v-if="sellPrice === 0" class="price-text">
            Free
          </div>
          <div v-else class="price-text">
            {{ currency }}{{ sellPrice| priceUnitConvert(minorUnit, isKeepTwoDecimal) }}
            <span v-if="courseDetail.perShowPrice !== undefined" class="per-lesson-text">{{ langContext.perLesson }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
}
</script>

<style lang="scss" scoped>
.courses-column-footer {
  width: 398px;
  padding: 20px;
  background: #fff;
  border-radius: 0 0 10px 10px;

  .suitable-tudents {
    margin-bottom: 20px;
  }

  .footer-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  .lessons-wrapper {
    height: 22px;
    line-height: 22px;
    padding: 0 8px;
    color: var(--txt-color-link);
    font-size: 12px;
    background: var(--txt-color-link3);
    border-radius: 11px;
  }

  .price-wrapper {
    display: flex;
    align-items: center;
    line-height: 24px;
  }

  .from-text {
    margin-right: 12px;
    color: var(--txt-color-lv2);
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  .price-text {
    color: var(--txt-color-link);
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
  }

  .per-lesson-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
