export default {
  props: {
    // 组件名称
    componentName: {
      default: '',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 内容项列表
    moduleList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      fileTypeMap: {
        1: 'pdf',
        2: 'word',
        3: 'excel',
        4: 'mp4',
      },
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin;
    },
  },
}
