<template>
  <div>
    <a v-if="url" :href="url" :target="target">
      <slot />
    </a>
    <div v-else class="diff-div-wraper">
      <slot />
    </div>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  mixins: [mixin],
}
</script>

<style lang="scss" scoped>
.diff-div-wraper {
  cursor: default;
}
</style>
