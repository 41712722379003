<template>
  <div class="dynamic-wrapper">
    <div ref="animation" />
  </div>
</template>
<script>
import mixin from "./mixin";
import lottie from "lottie-web";
import * as animationData from "./config/web.json";

export default {
  mixins: [mixin],
  data() {
    return {
      lottieBox: null,
      play: false,
    };
  },
  mounted() {
    this.handleScroll = () => {
      const animationDom = this.$refs.animation;
      const rect = animationDom.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      const elementTop = rect.top + scrollTop;
      const elementBottom = elementTop + rect.height;

      const viewportTop = scrollTop;
      const viewportBottom = scrollTop + windowHeight;

      if (
        (elementTop >= viewportTop && elementTop <= viewportBottom) ||
        (elementBottom >= viewportTop && elementBottom <= viewportBottom)
      ) {
        console.log("in view");
        if (!this.play) {
          this.play = true;
          this.lottieBox = lottie.loadAnimation({
            container: this.$refs.animation, // 包含动画的dom元素
            renderer: "svg", // 渲染出来的是什么格式
            loop: false, // 循环播放
            autoplay: false, // 自动播放
            animationData: animationData.default,
          });
          this.lottieBox.play();
          window.removeEventListener("scroll", this.handleScroll);
        }
      }
      // else {
      //   console.log('out of view');
      //   this.lottieBox.pause();
      // }
    };
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.dynamic-wrapper {
  width: 1240px;
  height: 100%;
  margin: 0 auto;
}
</style>
