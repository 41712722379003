export default {
  props: {
    /**
     * 分校识别码
     */
    locale: {
      default: '',
      type: String,
    },

    /**
     * 组件源数据
     */
    componentList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    const { minFirstScreenModule } = this.$store.state.customPage
    const tempComponentList = []
    // const schoolCode = this.$store.state.schoolCode;
    this.componentList.forEach((item, index) => {
      // if (item.type === 52 && !(schoolCode == '6001' || schoolCode == '8601')) return
      try {
        if (item.content) {
          const componentContent = JSON.parse(item.content)
          tempComponentList.push({
            key: `${componentContent.componentName}-${index}`,
            moduleId: item.id,
            componentName: componentContent.componentName,
            componentType: componentContent.componentType,
            componentTitle: componentContent.componentTitle,
            componentData: componentContent.componentData,
            registration: componentContent.registration || {},
          })
        } else {
          tempComponentList.push({
            key: `${item.componentName}-${index}`,
            moduleId: item.id,
            ...item,
          })
        }
      } catch (error) {
        console.error(error)
      }
    })
    if (process.client) {
      console.group('自定义页面数据')
      console.log(`页面路径: ${this.$route.path}`)
      console.log('组件数据', tempComponentList)
      console.log('组件数据2', this.componentList)
      console.groupEnd()
    }
    console.log('组件数据：', JSON.stringify(tempComponentList))

    // 初始渲染首屏组件模块
    const renderComponentList = tempComponentList.length > minFirstScreenModule ? tempComponentList.slice(0, minFirstScreenModule) : tempComponentList
    return {
      minFirstScreenModule,
      tempComponentList,
      renderComponentList,
    }
  },
  mounted() {
    // 异步渲染剩余组件模块
    if (this.tempComponentList.length > this.minFirstScreenModule) {
      this.renderComponentList = this.renderComponentList.concat(this.tempComponentList.slice(this.minFirstScreenModule))
    }
    let cls = 0;
    new PerformanceObserver((entryList) => {
      for (const entry of entryList.getEntries()) {
        if (!entry.hadRecentInput) {
          cls += entry.value;
          console.log('Current CLS value:', cls, entry);
        }
      }
    }).observe({type: 'layout-shift', buffered: true});
    if(this.$route.query.source_id){
      window.sessionStorage.setItem('source_id', this.$route.query.source_id);
    }
    console.log(window.sessionStorage.getItem('preference'),'preference');
    if(this.$route.query.preference){
      if(window.localStorage.getItem('preference') !== this.$route.query.preference){
        if(this.$route.query.preference === 'phone' || this.$route.query.preference === 'email'){
          window.localStorage.setItem('preference', this.$route.query.preference);
        }
      }
    }
    if(this.$route.query.sourceType){
      window.sessionStorage.setItem('source_type', this.$route.query.sourceType);
    }
  },
  methods: {
    getTrackData(componentData) {
      if (process.server) return {};
      return {
        cms_component_type: componentData.componentType,
        cms_component_name: componentData.componentName,
        cms_component_title: componentData.componentTitle,
        cms_component_id: componentData.moduleId,
        previous_source: this.$route.name,
        cms_component_platform: window.JsInjectionActivity ? 'app' : process.env.CLIENT_TYPE, // 添加平台标识
      }
    },
  },
}
